<template>
  <Card>
    <template #content>
      <h2 class="mt-0 text-xl">
        Generate Ad from Text
      </h2>
      <BaseTextarea
        v-model="form.adScript"
        fieldId="adScript"
        fieldName="adScript"
        fieldLabel="Ad Script"
        helperText="Add your script that should be read verbatim."
      />
      <div class="mt-3 flex justify-content-end">
        <Button
          label="Generate"
          :disabled="!form.adScript"
          :loading="adIsLoading"
          @click="onGenerateAd"
        />
      </div>

      <Card
        v-if="adUrl"
        class="bg-gray-100 mt-3"
        :pt="{
          body: {
            class: 'p-2'
          }
        }"
      >
        <template #content>
          <audio
            class="w-full"
            :src="adUrl"
            controls
          />
        </template>
      </Card>
    </template>
  </Card>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';

export default {
  computed: {
    ...mapStores(useMyUserStore),
  },
  data() {
    return {
      form: {
        adScript: '',
      },
      adIsLoading: false,
      adUrl: null,
    };
  },
  methods: {
    async onGenerateAd() {
      try {
        this.adIsLoading = true;

        const res = await api.createTextToSpeech({
          userId: this.myUserStore.userId,
          text: this.form.adScript,
        });

        this.adUrl = res.s3_url;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.adIsLoading = false;
      }
    },
  },
};
</script>
