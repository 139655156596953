import { defineStore } from 'pinia';
import * as api from '@/api';
import {
  LOCAL_STORAGE_VOICEPRINT_KEY,
  DEFAULT_VOICE_STABILITY,
  DEFAULT_VOICE_SIMILARITY_BOOST,
  DEFAULT_VOICE_STYLE_EXAGERATION,
  DEFAULT_VOICE_PITCH_CHANGE,
  DEFAULT_VOICE_SPEED_CHANGE,
  DEFAULT_VOICEPRINT_SAMPLE_TEXT,
  ROLE_USER_TYPE_HOST,
  ROLE_USER_TYPE_ADVERTISER,
  DEFAULT_ACCENT,
} from '@/constants';
import { useAuthStore } from './auth';
import { useRolesStore } from './roles';

export const useMyUserStore = defineStore('myUser', {
  state: () => ({
    myUser: null,
    myUserIsLoading: false,
    myVoiceSettings: null,
    myVoiceSamples: [],
    myVoiceSamplesAreLoading: false,
    myVoiceprint: localStorage.getItem(LOCAL_STORAGE_VOICEPRINT_KEY),
    myVoiceprintIsLoading: false,
    myVoiceprintScore: null,
    myVoiceprintScoreIsLoading: false,
  }),
  getters: {
    userId: (state) => ((state.myUser)
      ? state.myUser.id
      : null),
    fullname: (state) => (state.myUser
      ? (`${state.myUser.fname || ''} ${state.myUser.lname || ''}`).trimEnd()
      : ''),
    email: (state) => (state.myUser
      ? state.myUser.email
      : ''),
    userInitials: (state) => {
      const firstInitial = state.myUser && typeof state.myUser.fname === 'string'
        ? state.myUser.fname.charAt(0)
        : '';
      const lastInitial = state.myUser && typeof state.myUser.lname === 'string'
        ? state.myUser.lname.charAt(0)
        : '';

      return (`${firstInitial}${lastInitial}`).toUpperCase();
    },
    voiceId: (state) => (state.myUser
      && state.myUser.voice_id
      ? state.myUser.voice_id
      : null),
    voiceStability: (state) => (state.myUser
      && state.myUser.voice_stability
      && !Number.isNaN(Number(state.myUser.voice_stability))
      ? Number(state.myUser.voice_stability)
      : DEFAULT_VOICE_STABILITY),
    voiceSimilarityBoost: (state) => (state.myUser
      && state.myUser.voice_similarity_boost
      && !Number.isNaN(Number(state.myUser.voice_similarity_boost))
      ? Number(state.myUser.voice_similarity_boost)
      : DEFAULT_VOICE_SIMILARITY_BOOST),
    voiceStyleExaggeration: (state) => (state.myUser
      && state.myUser.style_exaggeration
      && !Number.isNaN(Number(state.myUser.style_exaggeration))
      ? Number(state.myUser.style_exaggeration)
      : DEFAULT_VOICE_STYLE_EXAGERATION),
    voicePitchChange: (state) => (state.myUser
      && state.myUser.pitch_change
      && !Number.isNaN(Number(state.myUser.pitch_change))
      ? Number(state.myUser.pitch_change)
      : DEFAULT_VOICE_PITCH_CHANGE),
    voiceSpeedChange: (state) => (state.myUser
      && state.myUser.speed_change
      && !Number.isNaN(Number(state.myUser.speed_change))
      ? Number(state.myUser.speed_change)
      : DEFAULT_VOICE_SPEED_CHANGE),
    voiceAccent: (state) => (state.myVoiceSettings
      && state.myVoiceSettings.voice_accent
      ? state.myVoiceSettings.voice_accent
      : DEFAULT_ACCENT),
    voiceGender: (state) => (state.myVoiceSettings
      && state.myVoiceSettings.voice_gender
      ? state.myVoiceSettings.voice_gender
      : null),
    myOrganization: (state) => (state.myUser
      && state.myUser.organization
      ? state.myUser.organization
      : null),
    isHostUser: (state) => {
      const rolesStore = useRolesStore();
      const matchingRole = state && state.myUser && state.myUser.role_id
        ? rolesStore.roles.find((item) => Number(item.id) === Number(state.myUser.role_id))
        : undefined;

      return !!(matchingRole && matchingRole.user_type === ROLE_USER_TYPE_HOST);
    },
    isAdvertiserUser: (state) => {
      const rolesStore = useRolesStore();
      const matchingRole = state && state.myUser && state.myUser.role_id
        ? rolesStore.roles.find((item) => Number(item.id) === Number(state.myUser.role_id))
        : undefined;

      return !!(matchingRole && matchingRole.user_type === ROLE_USER_TYPE_ADVERTISER);
    },
  },
  actions: {
    async getMyUser() {
      try {
        this.myUserIsLoading = true;

        const res = await api.getMe();
        this.myUser = res;
      } catch (error) {
        const authStore = useAuthStore();
        authStore.logout();
      } finally {
        this.myUserIsLoading = false;
      }
    },
    async getMyVoiceSamples() {
      try {
        this.myVoiceSamplesAreLoading = true;

        const res = await api.readUserAudioSamples(this.userId);
        this.myVoiceSamples = res;
      } finally {
        this.myVoiceSamplesAreLoading = false;
      }
    },
    async getMyVoiceprint(text = DEFAULT_VOICEPRINT_SAMPLE_TEXT) {
      try {
        this.myVoiceprintIsLoading = true;

        const res = await api.createTextToSpeech({
          userId: this.userId,
          text,
        });

        if (!res.s3_url) {
          throw new Error('Error generating voiceprint. Url not found');
        }

        this.myVoiceprint = res.s3_url;
        localStorage.setItem(LOCAL_STORAGE_VOICEPRINT_KEY, res.s3_url);
      } finally {
        this.myVoiceprintIsLoading = false;
      }
    },
    async getMyVoiceSettings() {
      const res = await api.readUserVoiceSettings(this.userId);
      this.myVoiceSettings = res;
    },
    async getMyVoiceprintScore() {
      try {
        // must have at least 1 voice sample and voice print to make comparison
        if (!(this.myVoiceSamples.length > 0 && this.myVoiceprint)) {
          return;
        }

        this.myVoiceprintScoreIsLoading = true;

        const res = await api.getVoiceComparisonScore(
          this.myVoiceprint,
          this.myVoiceSamples[0].s3_url,
        );

        this.myVoiceprintScore = res.similarity_percentage;
      } finally {
        this.myVoiceprintScoreIsLoading = false;
      }
    },
  },
});
