import ekozAxios from './instance/axios';

export const updateUserVoiceSettings = ({
  userId,
  voiceStability,
  voiceSimilarityBoost,
  styleExaggeration,
  pitchChange,
  speedChange,
}) => ekozAxios.request({
  method: 'post',
  url: '/voice_settings/set_voice_settings.php',
  data: {
    user_id: userId,
    voice_stability: voiceStability,
    voice_similarity_boost: voiceSimilarityBoost,
    style_exaggeration: styleExaggeration,
    pitch_change: pitchChange,
    speed_change: speedChange,
  },
});

export const readUserVoiceSettings = (userId) => ekozAxios.request({
  method: 'get',
  url: '/voice_settings/get_voice_settings.php',
  params: {
    user_id: userId,
  },
});

export const createUserVoicePrint = ({
  accent,
  gender,
  userId,
  files,
}) => {
  const formData = new FormData();

  formData.append('name', `voice_user_id_${userId}`);
  formData.append('accent', accent);
  formData.append('gender', gender);
  formData.append('user_id', userId);

  files.forEach((file) => formData.append('file_upload[]', file));

  return ekozAxios.request({
    method: 'post',
    url: '/voice_settings/new_voiceprint.php',
    data: formData,
  });
};

export const updateUserVoicePrint = ({
  userId,
  sampleIds,
  accent,
  gender,
}) => {
  const formData = new FormData();

  formData.append('user_id', userId);
  formData.append('accent', accent);
  formData.append('gender', gender);
  formData.append('sample_ids', JSON.stringify(sampleIds));

  return ekozAxios.request({
    method: 'post',
    url: '/voice_settings/edit_voiceprint.php',
    data: formData,
  });
};
