export const GENDER_ALL = 'all';
export const GENDER_MEN = 'men';
export const GENDER_WOMEN = 'women';

export const GENDER_OPTIONS = [
  {
    value: GENDER_ALL,
    label: 'All',
  },
  {
    value: GENDER_MEN,
    label: 'Men',
  },
  {
    value: GENDER_WOMEN,
    label: 'Women',
  },
];
