import ekozAxios from './instance/axios';

export const getVoiceComparisonScore = (fileOneUrl, fileTwoUrl) => {
  const formData = new FormData();

  formData.append('file1_url', fileOneUrl);
  formData.append('file2_url', fileTwoUrl);

  return ekozAxios.request({
    method: 'post',
    url: '/voice_comparison/compare_voice.php',
    data: formData,
  });
};
