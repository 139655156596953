<template>
  <CreateOrganizationDialog
    :visible="isCreateOrganizationDialogVisible"
    :isSubmitting="isCreateOrganizationSubmitting"
    :allowSkip="false"
    @submit="onSubmitOrganization"
  />
  <CampaignFormDialog
    v-model:visible="isCreateCampaignDialogVisible"
    @update:visible="($event) => {
      if ($event === false) onCancelCampaign();
    }"
    :isSubmitting="isCreateCampaignSubmitting"
    :categoryOptions="categoriesStore.categoryOptions"
    initialPreviousButtonText="Skip for now"
    @submit="onSubmitCampaign"
  />
</template>

<script>
import { DateTime } from 'luxon';
import { mapStores } from 'pinia';
import { useMyUserStore, useCategoriesStore, useCampaignsStore } from '@/stores';
import CreateOrganizationDialog from '@/components/createOrganizationDialog';
import CampaignFormDialog from '@/components/campaignFormDialog';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';

const generateCampaignSkipKey = (organizationId) => `skip_campaign_create_org_${organizationId}`;

export default {
  components: {
    CreateOrganizationDialog,
    CampaignFormDialog,
  },
  computed: {
    ...mapStores(useMyUserStore, useCategoriesStore, useCampaignsStore),
  },
  data() {
    return {
      isCreateOrganizationDialogVisible: false,
      isCreateOrganizationSubmitting: false,
      isCreateCampaignDialogVisible: false,
      isCreateCampaignSubmitting: false,
    };
  },
  async mounted() {
    await this.auditAccountSetup();
  },
  methods: {
    async auditAccountSetup() {
      try {
        // check if user has org
        const userHasOrganization = !!this.myUserStore.myOrganization;

        // if not then show create org dialog
        if (!userHasOrganization) {
          this.isCreateOrganizationDialogVisible = true;
          return;
        }

        this.isCreateOrganizationDialogVisible = false;

        const organizationId = this.myUserStore.myOrganization.id;

        const campaignSkipKey = generateCampaignSkipKey(organizationId);
        const skipCampaignCreate = !!localStorage.getItem(campaignSkipKey);
        if (skipCampaignCreate) {
          this.isCreateCampaignDialogVisible = false;
          return;
        }

        // check if user org has atleast 1 campaign
        await this.campaignsStore.getCampaigns({
          organizationId,
        });
        const campaigns = this.campaignsStore
          .getCampaignsByOrganizationId(organizationId);

        const organizationHasCampaigns = campaigns.length > 0;
        // if not then show create campaign dialog
        if (!organizationHasCampaigns) {
          this.isCreateCampaignDialogVisible = true;
          return;
        }

        this.isCreateCampaignDialogVisible = false;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error setting up account. Please refresh the page and try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitOrganization({
      organizationForm,
      invitedUsers,
    }) {
      try {
        const userHasSkippedForm = organizationForm.name === '';

        this.isCreateOrganizationSubmitting = true;

        const res = await api.createOrganization({
          name: organizationForm.name,
        });
        const organizationId = res.organization_id;
        await api.createAdvertiser({
          organizationId,
          name: organizationForm.name,
        });
        await api.updateUser({
          userId: this.myUserStore.myUser.id,
          organizationId,
        });

        if (!userHasSkippedForm) {
          this.$toast.add({
            severity: 'success',
            detail: 'Successfully created organization',
          });
        }

        if (invitedUsers.length > 0) {
          try {
            await Promise.all(invitedUsers.map((user) => api.inviteUser({
              email: user.email,
              organizationId,
            })));
          } catch (error) {
            const message = parseMessageFromError(error, 'Organization created, but failed to invite users. Please visit the organization page and re-invite users.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }

        await this.myUserStore.getMyUser();
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateOrganizationSubmitting = false;
      }
    },
    async onSubmitCampaign({ campaignForm }) {
      try {
        this.isCreateCampaignSubmitting = true;

        const targetAges = campaignForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const adScript = campaignForm.campaignScript ? campaignForm.campaignScript : null;
        const adDocument = campaignForm.adDocument instanceof File
          ? campaignForm.adDocument
          : null;

        await api.createCampaign({
          campaignName: campaignForm.name,
          organizationId: this.myUserStore.myOrganization.id,
          startDate: DateTime.fromJSDate(campaignForm.dates[0]).toISODate(),
          endDate: DateTime.fromJSDate(campaignForm.dates[1]).toISODate(),
          maxBudget: campaignForm.budget,
          budgetAllocation: campaignForm.budgetAllocation,
          targetGender: campaignForm.targetGender,
          targetAges,
          allowExplicit: campaignForm.allowExplicit,
          targetCategories: campaignForm.categories,
          adRunSlot: campaignForm.adRunSlot,
          adTitle: campaignForm.adTitle,
          adLength: campaignForm.adLength,
          bidCap: campaignForm.bidCap,
          frequencyCap: campaignForm.frequencyCap,
          adScript,
          adDocument,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created campaign',
        });

        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateCampaignSubmitting = false;
      }
    },
    async onCancelCampaign() {
      try {
        localStorage.setItem(generateCampaignSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>
