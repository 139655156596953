<template>
  <section
    :style="{
      maxWidth: this.maxWidth,
    }"
    class="page-container p-5 my-3"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '800px',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  margin: 0 auto;
}
</style>
