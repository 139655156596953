<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{ value: fieldValue, handleChange, errors }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
      >
        {{ fieldLabel }}
      </label>
      <slot name="helperText">
        <small
          v-if="helperText"
          :id="`${fieldId}-help`"
          v-html="helperText"
        />
      </slot>
      <InputGroup>
        <InputNumber
          :inputId="fieldId"
          :modelValue="fieldValue"
          @update:modelValue="handleChange"
          v-bind="$attrs"
          :invalid="errors.length > 0"
          :aria-describedby="helperText ? `${fieldId}-help` : ''"
        />
        <slot name="append" />
      </InputGroup>
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
