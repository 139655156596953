import axios from 'axios';
import { LOCAL_STORAGE_USER_TOKEN_KEY } from '@/constants';

const API_SERVER = process.env.VUE_APP_API_SERVER;

const axiosInstance = axios.create({
  baseURL: API_SERVER,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_KEY) || '';

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
