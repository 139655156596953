import { defineStore } from 'pinia';
import * as api from '@/api';

export const useProgramsStore = defineStore('programs', {
  state: () => ({
    programs: [],
    programsAreLoading: false,
  }),
  getters: {
    getProgramsByOrganizationId: (state) => (
      (organizationId) => state.programs
        .filter((item) => Number(item.organization_id) === Number(organizationId))
    ),
  },
  actions: {
    async getPrograms({ organizationId } = {}) {
      try {
        this.programsAreLoading = true;

        const res = await api.readPrograms({ organizationId });

        this.programs = res;
      } finally {
        this.programsAreLoading = false;
      }
    },
  },
});
