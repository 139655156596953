export const campaignsData = [
  {
    id: 1,
    advertiserName: 'Apple',
    campaign_name: 'Big Kahuna Burger',
    end_date: '2024-05-10',
    adUrl: '',
    revenue: 0,
    status: 'created',
    analytics: [
      {
        impressions: 10,
        date: '2024-04-01',
      },
      {
        impressions: 15,
        date: '2024-04-02',
      },
      {
        impressions: 18,
        date: '2024-04-03',
      },
      {
        impressions: 12,
        date: '2024-04-04',
      },
      {
        impressions: 20,
        date: '2024-04-05',
      },
    ],
  },
  {
    id: 2,
    advertiserName: 'IBM',
    campaign_name: 'Abstergo Ltd.',
    end_date: '2024-05-20',
    adUrl: '',
    revenue: 120,
    status: 'paused',
    analytics: [
      {
        impressions: 30,
        date: '2024-04-01',
      },
      {
        impressions: 35,
        date: '2024-04-02',
      },
      {
        impressions: 22,
        date: '2024-04-03',
      },
      {
        impressions: 10,
        date: '2024-04-04',
      },
      {
        impressions: 40,
        date: '2024-04-05',
      },
    ],
  },
  {
    id: 3,
    advertiserName: 'Philips',
    campaign_name: 'Biffco Enterprises',
    end_date: '2024-05-21',
    adUrl: '',
    revenue: 400,
    status: 'active',
    analytics: [
      {
        impressions: 12,
        date: '2024-04-01',
      },
      {
        impressions: 50,
        date: '2024-04-02',
      },
      {
        impressions: 30,
        date: '2024-04-03',
      },
      {
        impressions: 22,
        date: '2024-04-04',
      },
      {
        impressions: 33,
        date: '2024-04-05',
      },
    ],
  },
];
