export const BUDGET_ALLOCATION_UNIFORM_BUDGET = 'uniform';
export const BUDGET_ALLOCATION_AS_FAST_AS_POSSIBLE = 'afap';

export const BUDGET_ALLOCATION_OPTIONS = [
  {
    value: BUDGET_ALLOCATION_UNIFORM_BUDGET,
    label: 'Uniform',
  },
  {
    value: BUDGET_ALLOCATION_AS_FAST_AS_POSSIBLE,
    label: 'As fast as possible',
  },
];

export const BUDGET_ALLOCATION_DICTIONARY = BUDGET_ALLOCATION_OPTIONS.reduce((acc, item) => {
  acc[item.value] = item;

  return acc;
}, {});
