import ekozAxios from './instance/axios';

export const createGeneratedAd = ({
  userId,
  adFile,
  scriptMinutes = 0,
  scriptSeconds = 30,
}) => {
  const formData = new FormData();

  formData.append('user_id', userId);
  formData.append('script_minutes', scriptMinutes);
  formData.append('script_seconds', scriptSeconds);
  formData.append('ad_file', adFile);

  return ekozAxios.request({
    method: 'post',
    url: '/generated_ads/new_generated_ad.php',
    data: formData,
  });
};

export const setApprovalGeneratedAd = ({
  generatedAdId,
  approved,
  disapprovedReason,
}) => ekozAxios.request({
  method: 'post',
  url: '/generated_ads/set_approval_generated_ad.php',
  data: {
    approved,
    disapproved_reason: disapprovedReason,
    generated_ad_id: generatedAdId,
  },
});
