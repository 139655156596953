<template>
  <small>
    {{ remainingText }}
  </small>
</template>

<script>
export default {
  props: {
    limit: {
      type: Number,
    },
    textLength: {
      type: Number,
    },
  },
  computed: {
    remainingText() {
      const charactersRemaining = Math.max(this.limit - this.textLength, 0);

      return `${charactersRemaining} character${charactersRemaining === 1 ? '' : 's'} remaining`;
    },
  },
};
</script>
