<template>
  <Card
    :pt="{
      body: {
        class: 'px-4 py-3 line-height-3',
      },
    }"
  >
    <template #content>
      <div class="grid">
        <div class="col-3 py-0">
          <span class="text-sm text-gray-400">
            {{ compiledAd.org_name || '-' }}
          </span><br />
          <span class="text-lg font-bold">
            <router-link
              :to="{
                name: ROUTE_HOST_CAMPAIGN_DETAILS,
                params: {
                  adId: compiledAd.id,
                }
              }"
            >
              <Button
                class="p-0 text-left"
                link
                :label="compiledAd.campaign_name || '-'"
              />
            </router-link>
          </span>
        </div>

        <div class="col-2 py-0">
          <span class="text-sm text-gray-400">
            Ends {{ compiledAd.endDateDisplay }}
          </span><br />
          <span class="text-lg font-bold">
            {{ compiledAd.durationSubtext }}
          </span>
        </div>

        <div class="col-2 py-0 flex align-items-center">
          <span class="text-lg font-bold">
            {{ compiledAd.revenueDisplay }}
          </span>
        </div>

        <div class="col-2 py-0 flex align-items-center">
          <Chip
            :class="compiledAd.statusChipClass"
          >
            {{ compiledAd.statusChipValue }}
          </Chip>
        </div>

        <div class="col-3 py-0">
          <div class="analytics-column-container">
            <Skeleton
              v-if="impressionsAreLoading"
              class="h-full"
            />
            <Chart
              v-show="!impressionsAreLoading"
              class="h-3rem"
              type="line"
              :data="chartData"
              :options="chartOptions"
            />
            <Button
              v-if="isDropdownVisible"
              class="ad-options-button"
              icon="pi pi-ellipsis-v"
              text
              plain
              aria-haspopup="true"
              aria-controls="ad-row-menu"
              @click="(event) => this.$refs.adRowDropdown.toggle(event)"
            />
            <Menu
              id="ad-row-menu"
              class="right-align-menu"
              ref="adRowDropdown"
              popup
              :model="dropdownItems"
            />
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { DateTime } from 'luxon';
import { ROUTE_HOST_CAMPAIGN_DETAILS } from '@/router/routes';
import {
  AD_STATUS_ACTIVE,
  AD_STATUS_PAUSED,
} from '@/constants';
import { getAdStatusChip } from '@/utils/statuses';

export default {
  props: {
    ad: {
      type: Object,
    },
    impressions: {
      type: Array,
    },
    impressionsAreLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    compiledAd() {
      const { ad } = this;

      const startDateDisplay = DateTime.fromISO(ad.start_date)
        .toLocaleString(DateTime.DATE_SHORT);
      const endDateDisplay = DateTime.fromISO(ad.end_date)
        .toLocaleString(DateTime.DATE_SHORT);

      const campaignHasStarted = DateTime.fromISO(ad.start_date).diffNow('days').days < 0;
      let durationSubtext = '';

      if (campaignHasStarted) {
        const daysLeft = Math.max(
          Math.ceil(DateTime.fromISO(ad.end_date).diffNow('days').days),
          0,
        );

        durationSubtext = `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
      } else {
        const daysUntilStart = Math.ceil(DateTime.fromISO(ad.start_date).diffNow('days').days);

        durationSubtext = `${daysUntilStart} day${daysUntilStart === 1 ? '' : 's'} until start`;
      }

      const revenueDisplay = typeof ad.revenue === 'number'
        ? ad.revenue.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        })
        : '$0';

      const statusChip = getAdStatusChip(ad.status);

      return {
        ...ad,
        startDateDisplay,
        endDateDisplay,
        durationSubtext,
        revenueDisplay,
        statusChipClass: statusChip.class,
        statusChipValue: statusChip.value,
      };
    },
    chartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        datasets: [
          {
            label: 'Impressions',
            data: this.impressions
              ? this.impressions.map(({
                count,
                timestamp,
              }) => ({
                x: timestamp,
                y: count,
              }))
              : [],
            fill: false,
            borderColor: documentStyle.getPropertyValue('--blue-400'),
            borderWidth: 3,
            pointRadius: 0,
          },
        ],
      };
    },
    dropdownItems() {
      return [
        {
          label: 'Pause',
          command: () => {
            this.$emit('pauseAd', this.ad);
          },
          visible: () => this.ad && this.ad.status === AD_STATUS_ACTIVE,
        },
        {
          label: 'Resume',
          command: () => {
            this.$emit('resumeAd', this.ad);
          },
          visible: () => this.ad && this.ad.status === AD_STATUS_PAUSED,
        },
      ];
    },
    isDropdownVisible() {
      return this.ad
        && (
          this.ad.status === AD_STATUS_ACTIVE
          || this.ad.status === AD_STATUS_PAUSED
        );
    },
  },
  data() {
    return {
      ROUTE_HOST_CAMPAIGN_DETAILS,
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        layout: {
          padding: 4,
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-light-orange {
  background-color: #fef8f3;
}
.bg-light-green {
  background-color: #f4fdf7;
}
.bg-light-blue {
  background-color: #f5f9ff;
}

.analytics-column-container {
  height: 100%;
  padding-right: 40px;
  position: relative;
}
.ad-options-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
