<template>
  <section>
    <div class="pb-4">
      {{ dialogRef.data.text }}
    </div>
    <div class="flex justify-content-end">
      <Button
        label="Close"
        @click="this.dialogRef.close()"
      />
    </div>
  </section>
</template>

<script>
export default {
  inject: ['dialogRef'],
};
</script>
