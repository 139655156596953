<template>
  <Skeleton
    v-if="isLoading"
    height="74px"
  />
  <Card
    v-else-if="!isLoading && url"
    class="bg-gray-100"
    :pt="{
      body: {
        class: 'p-2'
      }
    }"
  >
    <template #content>
      <audio
        class="w-full"
        :src="url"
        controls
      />
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    url: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
