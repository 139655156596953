import { defineStore } from 'pinia';
import _sortBy from 'lodash/sortBy';
import * as api from '@/api';

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    categories: [],
    sensitiveCategories: [],
    categoriesAreLoading: false,
  }),
  getters: {
    categoryDictionary: (state) => state.categories.reduce((acc, category) => {
      acc[category.id] = category;

      return acc;
    }, {}),
    categoryOptions: (state) => _sortBy(
      state.categories.map((item) => ({
        value: Number(item.id),
        label: item.name,
      })),
      (item) => (typeof item.label === 'string' ? item.label.toLowerCase() : item.label),
    ),
    sensitiveCategoryOptions: (state) => _sortBy(
      state.sensitiveCategories.map((item) => ({
        value: Number(item.id),
        label: item.name,
      })),
      (item) => (typeof item.label === 'string' ? item.label.toLowerCase() : item.label),
    ),
    iabOptions: (state) => state.categories
      .sort((a, b) => (
        Number(a.id) - Number(b.id)
      )).map((item) => ({
        value: item.iab_code,
        label: `${item.iab_code} - ${item.name}`,
      })),
  },
  actions: {
    async getCategories() {
      try {
        this.categoriesAreLoading = true;

        const res = await api.readCategories();

        this.categories = res.data.normal || [];
        this.sensitiveCategories = res.data.sensitive || [];
      } finally {
        this.categoriesAreLoading = false;
      }
    },
  },
});
