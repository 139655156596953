<template>
  <Message
    class="m-0"
    severity="secondary"
    icon="pi pi-info-circle"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal text-sm',
      },
    }"
  >
    Based on your ad preferences we recommend a {{ cpmName }}
    of <strong>${{ bidCapRecommendation.min }}</strong>
    - <strong>${{ bidCapRecommendation.max }}</strong>
  </Message>
</template>

<script>
import { USER_TYPE_ADVERTISER, USER_TYPE_HOST } from '@/constants';
import { getBidCapRecommendation } from '@/utils/bidCaps';

export default {
  props: {
    userType: String,
    adRunSlot: String,
    adLength: Number,
  },
  computed: {
    cpmName() {
      switch (this.userType) {
        case USER_TYPE_ADVERTISER:
          return 'bid cap CPM';
        case USER_TYPE_HOST:
          return 'minimum CPM';
        default:
          return 'CPM';
      }
    },
    bidCapRecommendation() {
      return getBidCapRecommendation(
        this.userType,
        this.adRunSlot,
        this.adLength,
      );
    },
  },
};
</script>
