import ekozAxios from './instance/axios';

export const readUserAudioSamples = (userId) => ekozAxios.request({
  method: 'get',
  url: '/audio_samples/get_files.php',
  params: {
    user_id: userId,
  },
});

export const deleteAudioSample = (audioSampleId) => ekozAxios.request({
  method: 'get',
  url: '/audio_samples/delete_file.php',
  params: {
    id: audioSampleId,
  },
});

export const createAudioSamples = (userId, files) => {
  const formData = new FormData();

  formData.append('user_id', userId);
  files.forEach((file) => formData.append('files[]', file));

  return ekozAxios.request({
    method: 'post',
    url: '/audio_samples/add_file.php',
    data: formData,
  });
};
