<template>
  <PageContainer maxWidth="1200px">
    <div class="grid">
      <div class="col-12 sm:col sm:flex-grow-1">
        <PageTitle>Campaigns</PageTitle>
      </div>
      <div class="col-12 sm:col sm:flex-grow-0">
        <Button
          class="w-full text-wrap-nowrap"
          label="Create Campaign"
          @click="addCampaignDialogData.visible = true"
        />
      </div>
    </div>
    <div
      v-if="campaignsStore.campaignsAreLoading"
      class="grid"
    >
      <div
        v-for="index in 2"
        :key="index"
        class="col-12 md:col-6"
      >
        <AnalyticsLoadingCard />
      </div>
    </div>
    <div
      v-else
      class="grid"
    >
      <div class="col-12 md:col-6">
        <AnalyticsCard
          title="Impressions"
          :value="totalImpressions"
          :percentageChange="0"
        />
      </div>
      <div class="col-12 md:col-6">
        <AnalyticsCard
          title="Budget Spent"
          :value="null"
          :percentageChange="0"
          currency
        />
      </div>
    </div>

    <div class="mt-4 overflow-y-auto">
      <DataView
        :style="{
          minWidth: '1000px',
        }"
        :value="sortedCampaigns"
        :pt="{
          header: {
            class: 'bg-transparent border-0',
          },
          content: {
            class: 'bg-transparent',
          },
        }"
      >
        <template #header>
          <div class="grid grid-nogutter campaigns-table-header">
            <div
              class="col-3 cursor-pointer"
              @click="onSort(SORT_BY_NAME)"
              @keydown="onSort(SORT_BY_NAME)"
            >
              Name
              <i
                v-if="sort.by === SORT_BY_NAME"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_END_DATE)"
              @keydown="onSort(SORT_BY_END_DATE)"
            >
              Duration
              <i
                v-if="sort.by === SORT_BY_END_DATE"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_REVENUE)"
              @keydown="onSort(SORT_BY_REVENUE)"
            >
              Est. Payout
              <i
                v-if="sort.by === SORT_BY_REVENUE"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div
              class="col-2 cursor-pointer"
              @click="onSort(SORT_BY_STATUS)"
              @keydown="onSort(SORT_BY_STATUS)"
            >
              Status
              <i
                v-if="sort.by === SORT_BY_STATUS"
                style="font-size: 12px"
                :class="{
                  'pi': true,
                  'pi-arrow-down': sort.order === SORT_ORDER_DESC,
                  'pi-arrow-up': sort.order === SORT_ORDER_ASC,
                }"
              />
            </div>
            <div class="col-3">
              Analytics
            </div>
          </div>
        </template>
      </DataView>
      <!--
        render loading & campaigns outside of DataView. The re-rendering caused by DataView is
        causing bugs with charts rendered in CampaignRow
      -->
      <ul
        v-if="campaignsStore.campaignsAreLoading"
        class="campaigns-list list-none p-0 m-0"
      >
        <li
          v-for="index in 3"
          :key="index"
        >
          <CampaignLoadingRow />
        </li>
      </ul>
      <ul
        v-show="!campaignsStore.campaignsAreLoading"
        class="campaigns-list list-none p-0 m-0">
        <li
          v-for="item in sortedCampaigns"
          :key="item.id"
        >
          <CampaignRow
            :campaign="item"
            :impressions="analyticsStore.campaignImpressions[item.id] || []"
            :impressionsAreLoading="analyticsStore.campaignImpressionsAreLoading[item.id]
              || false"
            @editCampaign="onClickEditCampaign"
            @pauseCampaign="onClickPauseCampaign"
            @resumeCampaign="onClickResumeCampaign"
          />
        </li>
      </ul>
    </div>

    <CampaignFormDialog
      v-model:visible="addCampaignDialogData.visible"
      :isSubmitting="addCampaignDialogData.isSubmitting"
      :categoryOptions="categoriesStore.categoryOptions"
      @submit="onSubmitAddCampaign"
    />

    <CampaignFormDialog
      v-model:visible="editCampaignDialogData.visible"
      :isSubmitting="editCampaignDialogData.isSubmitting"
      :categoryOptions="categoriesStore.categoryOptions"
      :campaign="editCampaignDialogData.campaign"
      @submit="onSubmitEditCampaign"
    />

    <ConfirmDialog
      v-model:visible="pauseCampaignDialogData.visible"
      :isSubmitting="pauseCampaignDialogData.isSubmitting"
      header="Pause Campaign"
      @confirm="onSubmitPauseCampaign"
    >
      <p>
        Are you sure you want to pause the campaign
        <strong>
          {{ pauseCampaignDialogData.campaign
            ? pauseCampaignDialogData.campaign.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
      <p>
        Ads will stop serving until the campaign is resumed.
      </p>
    </ConfirmDialog>

    <ConfirmDialog
      v-model:visible="resumeCampaignDialogData.visible"
      :isSubmitting="resumeCampaignDialogData.isSubmitting"
      header="Resume Campaign"
      @confirm="onSubmitResumeCampaign"
    >
      <p>
        Are you sure you want to resume the campaign
        <strong>
          {{ resumeCampaignDialogData.campaign
            ? resumeCampaignDialogData.campaign.campaign_name
            : '-'
          }}
        </strong>
        ?
      </p>
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { mapStores } from 'pinia';
import _orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import {
  useCampaignsStore,
  useCategoriesStore,
  useMyUserStore,
  useAnalyticsStore,
} from '@/stores';
import CampaignFormDialog from '@/components/campaignFormDialog';
import ConfirmDialog from '@/components/confirmDialog';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { ExistingDocument } from '@/utils/document';
import AnalyticsCard from './components/analyticsCard';
import AnalyticsLoadingCard from './components/analyticsLoadingCard';
import CampaignRow from './components/campaignRow';
import CampaignLoadingRow from './components/campaignLoadingRow';
import { campaignsData } from './data';

const SORT_ORDER_ASC = 'asc';
const SORT_ORDER_DESC = 'desc';

const SORT_BY_NAME = 'campaign_name';
const SORT_BY_END_DATE = 'end_date';
const SORT_BY_REVENUE = 'revenue';
const SORT_BY_STATUS = 'status';

export default {
  components: {
    AnalyticsCard,
    AnalyticsLoadingCard,
    CampaignRow,
    CampaignLoadingRow,
    CampaignFormDialog,
    ConfirmDialog,
  },
  computed: {
    ...mapStores(useCampaignsStore, useMyUserStore, useCategoriesStore, useAnalyticsStore),
    sortedCampaigns() {
      const campaigns = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.campaignsStore.getCampaignsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      return _orderBy(campaigns, [this.sort.by], [this.sort.order]);
    },
    totalImpressions() {
      const campaigns = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.campaignsStore.getCampaignsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      const totalImpressions = campaigns.reduce((acc, campaign) => {
        if (this.analyticsStore.campaignImpressions[campaign.id]) {
          const totalCampaignImpressions = this.analyticsStore.campaignImpressions[campaign.id]
            .reduce((innerAcc, item) => innerAcc + item.count, 0);

          return acc + totalCampaignImpressions;
        }

        return acc;
      }, 0);

      return totalImpressions;
    },
  },
  data() {
    return {
      SORT_ORDER_ASC,
      SORT_ORDER_DESC,
      SORT_BY_NAME,
      SORT_BY_END_DATE,
      SORT_BY_REVENUE,
      SORT_BY_STATUS,
      sort: {
        by: SORT_BY_END_DATE,
        order: SORT_ORDER_ASC,
      },
      campaigns: campaignsData,
      addCampaignDialogData: {
        visible: false,
        isSubmitting: false,
      },
      editCampaignDialogData: {
        visible: false,
        isSubmitting: false,
        campaign: null,
      },
      pauseCampaignDialogData: {
        visible: false,
        isSubmitting: false,
        campaign: null,
      },
      resumeCampaignDialogData: {
        visible: false,
        isSubmitting: false,
        campaign: null,
      },
    };
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    onSort(sortBy) {
      if (this.sort.by === sortBy && this.sort.order === SORT_ORDER_ASC) {
        this.sort.order = SORT_ORDER_DESC;
      } else if (this.sort.by === sortBy && this.sort.order === SORT_ORDER_DESC) {
        this.sort.order = SORT_ORDER_ASC;
      } else {
        this.sort = {
          by: sortBy,
          order: SORT_ORDER_ASC,
        };
      }
    },
    async getCampaigns() {
      try {
        await this.campaignsStore.getCampaigns({
          organizationId: this.myUserStore.myOrganization.id,
        });
        const campaigns = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
          ? this.campaignsStore
            .getCampaignsByOrganizationId(this.myUserStore.myUser.organization_id)
          : [];

        campaigns.forEach((campaign) => {
          this.analyticsStore.getCampaignImpressions({
            campaignId: campaign.id,
            startDate: campaign.start_date,
            endDate: campaign.end_date,
          }).catch((error) => {
            console.log(`Error loading analytics for campaign ${campaign.id}`, error);
          });
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitAddCampaign({ campaignForm }) {
      try {
        this.addCampaignDialogData.isSubmitting = true;

        const targetAges = campaignForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const adScript = campaignForm.campaignScript ? campaignForm.campaignScript : null;
        const adDocument = campaignForm.adDocument instanceof File
          ? campaignForm.adDocument
          : null;

        await api.createCampaign({
          campaignName: campaignForm.name,
          organizationId: this.myUserStore.myOrganization.id,
          startDate: DateTime.fromJSDate(campaignForm.dates[0]).toISODate(),
          endDate: DateTime.fromJSDate(campaignForm.dates[1]).toISODate(),
          maxBudget: campaignForm.budget,
          budgetAllocation: campaignForm.budgetAllocation,
          targetGender: campaignForm.targetGender,
          targetAges,
          allowExplicit: campaignForm.allowExplicit,
          targetCategories: campaignForm.categories,
          adRunSlot: campaignForm.adRunSlot,
          adTitle: campaignForm.adTitle,
          adLength: campaignForm.adLength,
          bidCap: campaignForm.bidCap,
          frequencyCap: campaignForm.frequencyCap,
          adScript,
          adDocument,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created campaign',
        });

        this.addCampaignDialogData.visible = false;

        this.getCampaigns();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.addCampaignDialogData.isSubmitting = false;
      }
    },
    async onClickEditCampaign(campaign) {
      this.editCampaignDialogData = {
        ...this.editCampaignDialogData,
        visible: true,
        campaign,
      };
    },
    async onSubmitEditCampaign({ campaignForm, campaign }) {
      try {
        this.editCampaignDialogData.isSubmitting = true;

        const targetAges = campaignForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const adScript = campaignForm.campaignScript ? campaignForm.campaignScript : null;
        const adDocument = campaignForm.adDocument instanceof File
          ? campaignForm.adDocument
          : null;
        const uploadedTalkingPointsDocumentS3Url = campaignForm
          .adDocument instanceof ExistingDocument
          ? campaignForm.adDocument.url
          : null;

        await api.updateCampaign({
          campaignId: campaign.id,
          campaignName: campaignForm.name,
          organizationId: this.myUserStore.myOrganization.id,
          startDate: DateTime.fromJSDate(campaignForm.dates[0]).toISODate(),
          endDate: DateTime.fromJSDate(campaignForm.dates[1]).toISODate(),
          maxBudget: campaignForm.budget,
          budgetAllocation: campaignForm.budgetAllocation,
          targetGender: campaignForm.targetGender,
          targetAges,
          allowExplicit: campaignForm.allowExplicit,
          targetCategories: campaignForm.categories,
          adRunSlot: campaignForm.adRunSlot,
          status: campaign.status,
          adTitle: campaignForm.adTitle,
          adLength: campaignForm.adLength,
          bidCap: campaignForm.bidCap,
          frequencyCap: campaignForm.frequencyCap,
          adScript,
          adDocument,
          uploadedTalkingPointsDocumentS3Url,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated campaign',
        });

        this.editCampaignDialogData.visible = false;
        this.editCampaignDialogData.campaign = null;

        this.getCampaigns();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.editCampaignDialogData.isSubmitting = false;
      }
    },
    onClickPauseCampaign(campaign) {
      this.pauseCampaignDialogData = {
        ...this.pauseCampaignDialogData,
        visible: true,
        campaign,
      };
    },
    async onSubmitPauseCampaign() {
      try {
        this.pauseCampaignDialogData.isSubmitting = true;

        await api.pauseCampaign({
          campaignId: this.pauseCampaignDialogData.campaign.id,
          action: 'pause',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully paused campaign',
        });

        this.pauseCampaignDialogData.visible = false;
        this.pauseCampaignDialogData.campaign = null;

        this.getCampaigns();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error pausing campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.pauseCampaignDialogData.isSubmitting = false;
      }
    },
    onClickResumeCampaign(campaign) {
      this.resumeCampaignDialogData = {
        ...this.resumeCampaignDialogData,
        visible: true,
        campaign,
      };
    },
    async onSubmitResumeCampaign() {
      try {
        this.resumeCampaignDialogData.isSubmitting = true;

        await api.pauseCampaign({
          campaignId: this.resumeCampaignDialogData.campaign.id,
          action: 'resume',
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully resumed campaign',
        });

        this.resumeCampaignDialogData.visible = false;
        this.resumeCampaignDialogData.campaign = null;

        this.getCampaigns();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error resuming campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.resumeCampaignDialogData.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table-header {
  color: var(--gray-400);
  font-size: 0.875rem;
}
.campaigns-list {
  min-width: 1000px;

  li:not(:first-child) {
    margin-top: 1rem;
  }
}
</style>
