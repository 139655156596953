<template>
  <div class="campaign-summary-container">
    <!-- sidebar summary -->
    <Card
      class="bg-dark-grey h-full relative compaign-summary-side overflow-y-auto"
      :pt="{
        body: {
          class: 'p-0'
        }
      }"
    >
      <template #content>
        <div class="bg-primary p-4 pb-6 text-white border-round-top-xl">
          <h2 class="text-xl mt-0 mb-4 font-bold">
            Campaign Summary
          </h2>

          <div class="font-bold">
            {{ campaignName || '-' }}
          </div>
          <div
            v-if="startDate || endDate"
            class="mt-1 text-sm text-gray-100"
          >
            {{ formattedStartDate }}
            - {{ formattedEndDate }}
          </div>

          <Divider />

          <table class="campaign-summary-table mt-2">
            <tbody>
              <tr>
                <td>Your Budget</td>
                <td>{{ formattedBudget }}</td>
              </tr>
              <tr>
                <td>Bid Cap</td>
                <td>{{ formattedBidCap }}</td>
              </tr>
              <tr>
                <td>Ad Length</td>
                <td>{{ adLength ? `${adLength}s` : '-' }}</td>
              </tr>
              <tr>
                <td>Ad Slot</td>
                <td>{{ formattedAdRunSlot }}</td>
              </tr>
            </tbody>
          </table>
          <Card class="bg-grey campaign-summary-impressions-card mt-2">
            <template #content>
              <div class="text-sm">
                Target impressions acquired
              </div>
              <div class="mt-3 flex justify-content-between">
                <span class="text-primary font-bold">0</span>

                <span class="font-bold">{{ formattedTotalImpressions }}</span>
              </div>
              <MeterGroup
                class="mt-2"
                :value="[{
                  value: 0,
                  color: '#f26522',
                }]"
                :pt="{
                  labelList: {
                    class: 'hidden',
                  }
                }"
              />
            </template>
          </Card>
        </div>
      </template>
    </Card>

    <!-- top summary -->
    <Panel
      class="bg-primary compaign-summary-top"
      header="Campaign Summary"
      toggleable
      collapsed
      :pt="{
        header: {
          class: 'text-white',
        },
        content: {
          class: 'p-0'
        },
        pcToggleButton: {
          class: 'text-white',
        },
      }"
    >
      <div class="px-4 pt-4">
        <div class="font-bold text-white">
          {{ campaignName || '-' }}
        </div>
        <div
          v-if="startDate || endDate"
          class="mt-1 text-sm text-gray-100"
        >
          {{ formattedStartDate }}
          - {{ formattedEndDate }}
        </div>

        <Divider />
      </div>

      <table class="campaign-summary-table px-4 pt-4 mt-2 bg-primary">
        <tbody>
          <tr>
            <td>Your Budget</td>
            <td>{{ formattedBudget }}</td>
          </tr>
          <tr>
            <td>Bid Cap</td>
            <td>{{ formattedBidCap }}</td>
          </tr>
          <tr>
            <td>Ad Length</td>
            <td>{{ adLength ? `${adLength}s` : '-' }}</td>
          </tr>
          <tr>
            <td>Ad Slot</td>
            <td>{{ formattedAdRunSlot }}</td>
          </tr>
        </tbody>
      </table>
      <Card class="bg-grey text-white border-noround-top">
        <template #content>
          <div class="text-sm">
            Target impressions acquired
          </div>
          <div class="mt-3 flex justify-content-between">
            <span class="text-primary font-bold">0</span>

            <span class="font-bold">{{ formattedTotalImpressions }}</span>
          </div>
          <MeterGroup
            class="mt-2"
            :value="[{
              value: 0,
              color: '#f26522',
            }]"
            :pt="{
              labelList: {
                class: 'hidden',
              }
            }"
          />
        </template>
      </Card>
    </Panel>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { AD_RUN_SLOT_OPTIONS } from '@/constants';

export default {
  props: {
    campaignName: {
      type: String,
    },
    startDate: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    endDate: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    budget: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    bidCap: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    adRunSlot: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    adLength: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  computed: {
    formattedBudget() {
      return typeof this.budget === 'number'
        ? this.budget.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        }).toString()
        : '-';
    },
    formattedBidCap() {
      return typeof this.bidCap === 'number'
        ? this.bidCap.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        }).toString()
        : '-';
    },
    formattedAdRunSlot() {
      if (!this.adRunSlot) {
        return '-';
      }

      const matchingAdRunSlot = AD_RUN_SLOT_OPTIONS
        .find((item) => item.value === this.adRunSlot);

      return matchingAdRunSlot
        ? matchingAdRunSlot.label
        : this.adRunSlot;
    },
    formattedTotalImpressions() {
      if (typeof this.budget !== 'number' || typeof this.bidCap !== 'number') {
        return '-';
      }

      const ONE_THOUSAND = 1000;

      const impressions = Math.round((this.budget / this.bidCap) * ONE_THOUSAND);

      return impressions.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      });
    },
    formattedStartDate() {
      return this.startDate
        ? DateTime.fromJSDate(this.startDate).toLocaleString({ month: 'short', day: 'numeric' })
        : '';
    },
    formattedEndDate() {
      return this.endDate
        ? DateTime.fromJSDate(this.endDate).toLocaleString({ month: 'short', day: 'numeric' })
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.campaign-summary-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  z-index: 1;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 260px;
  }
  @media screen and (
    min-width: #{map-get($breakpoints, 'lg')}
  ) {
    width: 300px;
  }
}
.compaign-summary-side {
  display: none;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    display: block;
  }
}
.compaign-summary-top {
  display: block;

  :deep(.p-panel-header-icon) {
    color: white;
  }

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    display: none;
  }
}
.campaign-summary-table {
  width: 100%;
  tbody {
    tr {
      td {
        padding-bottom: 0.4375rem;
      }

      td:nth-child(1) {
        color: var(--gray-100);
        font-size: 0.875rem;
      }
      td:nth-child(2) {
        text-align: right;
        color: white;
      }
    }
  }
}
.campaign-summary-impressions-card {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  color: white;
}
</style>
