<template>
  <Card
    class="bg-grey text-white mt-3"
    :pt="{
      body: {
        class: 'px-3 py-3',
      },
    }"
    :key="name"
  >
    <template #content>
      <div class="flex justify-content-between">
        <label
          className="text-sm"
          :for="formId"
        >
          <i
            v-if="tooltip"
            class="pi pi-info-circle text-indigo-400 mr-1"
            v-tooltip.top="tooltip"
          />
          {{ name }}
        </label>
        <span class="pl-2 text-sm">
          <template v-if="transformFunction">
            {{ transformFunction(modelValue) }}
          </template>
          <template v-else>
            {{ modelValue }}
          </template>
          {{ labelUnit }}
        </span>
      </div>
      <div class="px-2 pt-3 pb-2">
        <Slider
          :id="formId"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)"
          :min="min || 0"
          :max="max || 100"
          :step="step || 1"
        />
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    formId: {
      type: String,
    },
    modelValue: {
      type: Number,
    },
    tooltip: {
      type: String,
    },
    labelUnit: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
    },
    transformFunction: {
      type: Function,
    },
  },
};
</script>
