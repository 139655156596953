<template>
  <div class="field flex flex-column">
    <div class="mb-2 font-bold">
      Hosting Platforms
    </div>
    <small class="inline-block mb-2">
      These are the platforms where your podcast are hosted.
      This allows Ekoz to create and run ads through your podcast.
    </small>

    <ul class="mt-3 mb-0 p-0 list-none">
      <!-- TAP -->
      <li class="border-gray-200 border-top-1 pt-2">
        <BaseInputNumber
          v-if="loadedPlatforms.tap"
          :modelValue="tapProgramId"
          @update:modelValue="$emit('update:tapProgramId', $event)"
          fieldId="tapProgramId"
          fieldName="tapProgramId"
          fieldLabel="Triton Station ID"
          :format="false"
          placeholder="Enter ID"
        >
          <template #append>
            <Button
              v-tooltip="'Remove hosting platform'"
              icon="pi pi-trash"
              severity="danger"
              @click="onDeleteTapField"
            />
          </template>
        </BaseInputNumber>
      </li>
    </ul>

     <Button
      class="mt-2"
      @click="isVisible = true"
      label="Add Platform"
      outlined
     />
  </div>
  <Sidebar
    v-model:visible="isVisible"
    class="w-full sm:w-40rem bg-gray-50"
    position="right"
    header="Add Hosting Platform"
    :pt="{
      content: {
        class: 'flex flex-column'
      }
    }"
  >
    <div class="flex flex-column overflow-y-auto overflow-x-hidden flex-grow-1">
      <Message
        v-if="availablePlatformOptions.length === 0"
        class="mt-0"
        severity="info"
        :closable="false"
        :pt="{
          icon: {
            class: 'mt-1 text-xl align-self-start',
          },
          text: {
            class: 'font-normal text-sm',
          },
        }"
      >
        You have utilized all available hosting platforms.
      </Message>

      <small class="inline-block">
        Choose the hosting platform that you want to add, then follow the instructions.
      </small>

      <Dropdown
        class="mt-3"
        v-model="newPlatform"
        :options="availablePlatformOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Hosting Platform"
        :disabled="availablePlatformOptions.length === 0"
      />

      <!-- TAP Form -->
       <Card
        v-if="newPlatform === PLATFORM_KEY_TAP"
        class="mt-3 p-3 bg-grey text-white"
       >
        <template #content>
          <ol class="instructions-list">
            <li>
              Log into your
              <a
                class="text-primary"
                href="https://omnystudio.com/login"
                target="_blank"
              >
                OmnyStudio <i class="pi pi-external-link text-xs" />
              </a>
              account.
            </li>
            <li>
              Navigate to the "Programs" page and click into your podcast.
            </li>
            <li>
              In the left hand menu, click "Program settings".
            </li>
            <li>
              Scroll down to the "Additional information" section and look for "Triton Station ID".
              The ID should be a 6 digit number.
            </li>
          </ol>

          <VeeForm
            class="mt-4"
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="tapSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              @submit.prevent="handleSubmit($event, onSubmitTap)"
            >
              <BaseInputNumber
                v-model="tapForm.tapProgramId"
                fieldId="tapProgramId"
                fieldName="tapProgramId"
                fieldLabel="Triton Station ID"
                :format="false"
                placeholder="Enter ID"
              />

              <div class="flex justify-content-end">
                <Button
                  label="Submit"
                  type="submit"
                />
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>
    </div>
  </Sidebar>
</template>

<script>
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { number, object } from 'yup';

const PLATFORM_KEY_TAP = 'tap';

const PLATFORM_OPTIONS = [
  {
    label: 'Triton',
    value: PLATFORM_KEY_TAP,
  },
];

// TODO - move tap form into its own component

export default {
  props: {
    tapProgramId: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  computed: {
    availablePlatformOptions() {
      const loadedPlatformsKeys = Object.keys(this.loadedPlatforms);

      return PLATFORM_OPTIONS
        .filter((item) => !loadedPlatformsKeys.includes(item.value));
    },
  },
  data() {
    return {
      isVisible: false,
      // determines if platform should be shown in UI
      loadedPlatforms: {},
      newPlatform: null,
      PLATFORM_KEY_TAP,
      tapSchema: object({
        tapProgramId: number().required('TAP program ID is required'),
      }),
      tapForm: {
        tapProgramId: null,
      },
    };
  },
  mounted() {
    if (this.tapProgramId) {
      this.loadedPlatforms = {
        ...this.loadedPlatforms,
        [PLATFORM_KEY_TAP]: true,
      };
    }
  },
  methods: {
    onDeleteTapField() {
      this.$emit('update:tapProgramId', null);
      if (this.loadedPlatforms[PLATFORM_KEY_TAP]) {
        delete this.loadedPlatforms[PLATFORM_KEY_TAP];
      }
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onSubmitTap({ tapProgramId }) {
      this.$emit('update:tapProgramId', tapProgramId);
      this.loadedPlatforms = {
        ...this.loadedPlatforms,
        [PLATFORM_KEY_TAP]: true,
      };

      this.$toast.add({
        severity: 'success',
        detail: 'Successfully added Triton to hosting platform list',
      });

      this.clearPlatformDropdown();
    },
    clearPlatformDropdown() {
      this.newPlatform = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.instructions-list {
  margin-top: 0;

  li:not(first-child) {
    margin-top: 0.5rem;
  }
}
</style>
