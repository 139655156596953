import {
  AD_STATUS_ACTIVE,
  AD_STATUS_COMPLETED,
  AD_STATUS_CREATED,
  AD_STATUS_PAUSED,
  AD_STATUS_PENDING,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_COMPLETED,
  CAMPAIGN_STATUS_CREATED,
  CAMPAIGN_STATUS_PAUSED,
  CAMPAIGN_STATUS_PENDING,
} from '@/constants';

/**
 * Get status chip by ad status
 *
 * @param {string} status
 * @returns {Object} chip
 * @returns {string} chip.class
 * @returns {string} chip.value
 */
export const getAdStatusChip = (status) => {
  switch (status) {
    case AD_STATUS_CREATED:
      return {
        class: 'bg-light-blue text-blue-500',
        value: 'Created',
      };
    case AD_STATUS_PENDING:
      return {
        class: 'bg-orange-100 text-orange-500',
        value: 'Pending',
      };
    case AD_STATUS_ACTIVE:
      return {
        class: 'bg-light-green text-green-500',
        value: 'Active',
      };
    case AD_STATUS_PAUSED:
      return {
        class: 'bg-red-100 text-red-500',
        value: 'Paused',
      };
    case AD_STATUS_COMPLETED:
      return {
        class: 'bg-gray-100 text-gray-500',
        value: 'Completed',
      };
    default:
      // do nothing
  }

  return {
    class: '',
    value: 'Unknown',
  };
};

/**
 * Get status chip by campaign status
 *
 * @param {string} status
 * @returns {Object} chip
 * @returns {string} chip.class
 * @returns {string} chip.value
 */
export const getCampaignStatusChip = (status) => {
  switch (status) {
    case CAMPAIGN_STATUS_CREATED:
      return {
        class: 'bg-light-blue text-blue-500',
        value: 'Created',
      };
    case CAMPAIGN_STATUS_PENDING:
      return {
        class: 'bg-orange-100 text-orange-500',
        value: 'Pending',
      };
    case CAMPAIGN_STATUS_ACTIVE:
      return {
        class: 'bg-light-green text-green-500',
        value: 'Active',
      };
    case CAMPAIGN_STATUS_PAUSED:
      return {
        class: 'bg-red-100 text-red-500',
        value: 'Paused',
      };
    case CAMPAIGN_STATUS_COMPLETED:
      return {
        class: 'bg-gray-100 text-gray-500',
        value: 'Completed',
      };
    default:
      // do nothing
  }

  return {
    class: '',
    value: 'Unknown',
  };
};
