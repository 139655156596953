<template>
  <VeeField
    :name="fieldName"
    :modelValue="modelValue"
    v-slot="{ errors }"
  >
    <div
      :class="{
        'mb-2 font-bold': true,
        'text-red-400': errors.length > 0,
      }"
    >
      {{  fieldLabel }}
    </div>
    <small class="inline-block mb-2">
      {{ helperText }}
    </small>
    <div class="flex align-items-center">
      <span
        :class="{
          'text-red-400': errors.length > 0
        }"
      >
        {{ modelValue.length }} selected
      </span>

      <Button
        class="ml-2"
        label="Select Categories"
        outlined
        @click="onClickSelectCategories"
      />
    </div>
    <small
      v-if="errors.length > 0"
      class="text-red-400 inline-block mt-2"
    >
      {{ errors[0] }}
    </small>
  </VeeField>
  <Sidebar
    v-model:visible="isSidebarVisible"
    class="w-full sm:w-40rem"
    position="right"
    header="Select Categories"
    :pt="{
      content: {
        class: 'flex flex-column'
      }
    }"
  >
    <div class="flex flex-column overflow-y-auto overflow-x-hidden flex-grow-1">
      <small
        class="inline-block mb-3"
        v-if="helperText"
      >
        {{ helperText }}
      </small>

      <div>
        <Button
          label="Select all"
          :disabled="selectedCategories.length === categoryOptions.length"
          size="small"
          text
          :onClick="onClickSelctAll"
        />
        <Button
          class="ml-2"
          label="Clear all"
          :disabled="selectedCategories.length !== categoryOptions.length"
          size="small"
          text
          :onClick="onClickClearAll"
        />
      </div>

      <div class="mt-3">
        <div class="grid">
          <div
            v-for="column in formattedCategoryOptions"
            :key="column.id"
            class="col-12 sm:col-6 py-0"
          >
            <div
              class="mt-2"
              v-for="item in column.categories"
              :key="item.value"
            >
              <Checkbox
                v-model="selectedCategories"
                :value="item.value"
                :inputId="`category-${item.value}`"
              />
              <label
                class="ml-2"
                :for="`category-${item.value}`"
              >
                {{ item.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-content-end pt-2">
      <Button
        label="Cancel"
        text
        plain
        @click="isSidebarVisible = false"
      />
      <Button
        label="Submit"
        @click="onSubmit"
      />
    </div>
  </Sidebar>
</template>

<script>
export default {
  props: {
    categoryOptions: {
      type: Array,
      required: true,
    },
    fieldLabel: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    helperText: {
      type: String,
    },
  },
  computed: {
    // format categories into 2 columns
    formattedCategoryOptions() {
      const halfwayIndex = Math.ceil(this.categoryOptions.length / 2);

      return [
        {
          id: 0,
          categories: this.categoryOptions.slice(0, halfwayIndex),
        },
        {
          id: 1,
          categories: this.categoryOptions.slice(halfwayIndex),
        },
      ];
    },
  },
  data() {
    return {
      isSidebarVisible: false,
      // local copy of selected categories
      // this allows user to make changes before submitting
      selectedCategories: [],
    };
  },
  methods: {
    onClickSelectCategories() {
      this.selectedCategories = [...this.modelValue];
      this.isSidebarVisible = true;
    },
    onSubmit() {
      this.$emit('update:modelValue', this.selectedCategories);
      this.isSidebarVisible = false;
    },
    onClickSelctAll() {
      this.selectedCategories = this.categoryOptions.map((option) => option.value);
    },
    onClickClearAll() {
      this.selectedCategories = [];
    },
  },
};
</script>
