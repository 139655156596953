<template>
  <div></div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores';

export default {
  computed: {
    ...mapStores(useAuthStore),
  },
  mounted() {
    this.authStore.logout();
    this.$router.push('/login');
  },
};
</script>
