export const AD_RUN_SLOT_PRE_ROLL = 'pre-roll';
export const AD_RUN_SLOT_MID_ROLL = 'mid-roll';
export const AD_RUN_SLOT_POST_ROLL = 'post-roll';

export const AD_RUN_SLOT_OPTIONS = [
  {
    value: AD_RUN_SLOT_PRE_ROLL,
    label: 'Pre-roll',
  },
  {
    value: AD_RUN_SLOT_MID_ROLL,
    label: 'Mid-roll',
  },
  {
    value: AD_RUN_SLOT_POST_ROLL,
    label: 'Post-roll',
  },
];
