import { defineStore } from 'pinia';
import * as api from '@/api';
import { ROLE_USER_TYPE_HOST, ROLE_USER_TYPE_ADVERTISER, ROLE_AUTHORITY_TYPE_ADMIN } from '@/constants';

export const useRolesStore = defineStore('roles', {
  state: () => ({
    roles: [],
    rolesAreLoading: false,
  }),
  getters: {
    advertiserAdminRole: (state) => state.roles
      .find((item) => (item.user_type === ROLE_USER_TYPE_ADVERTISER
        && item.authority_type === ROLE_AUTHORITY_TYPE_ADMIN)),
    advertiserRoleOptions: (state) => state.roles
      .filter((item) => item.user_type === ROLE_USER_TYPE_ADVERTISER)
      .map((item) => ({
        value: Number(item.id),
        label: item.name,
      })),
    hostAdminRole: (state) => state.roles
      .find((item) => (item.user_type === ROLE_USER_TYPE_HOST
        && item.authority_type === ROLE_AUTHORITY_TYPE_ADMIN)),
    hostRoleOptions: (state) => state.roles
      .filter((item) => item.user_type === ROLE_USER_TYPE_HOST)
      .map((item) => ({
        value: Number(item.id),
        label: item.name,
      })),
  },
  actions: {
    async getRoles() {
      try {
        this.rolesAreLoading = true;

        const res = await api.readRoles();
        this.roles = res.roles;
      } finally {
        this.rolesAreLoading = false;
      }
    },
  },
});
