/* eslint-disable no-param-reassign */
// Custom toast service that mimics primevue toastservice (https://github.com/primefaces/primevue/blob/master/components/lib/toastservice/ToastService.js)
// This is needed to provide default options to the toast component
import ToastEventBus from 'primevue/toasteventbus';
import { PrimeVueToastSymbol } from 'primevue/usetoast';

const DEFAULT_TOAST_DURATION_IN_MILLISECONDS = 4000;
const DEFAULT_TOAST_SUMMARY_DICTIONARY = {
  success: 'Success',
  info: 'Info',
  error: 'Error',
  warn: 'Warning',
};

export default {
  install: (app) => {
    const ToastService = {
      add: (message) => {
        ToastEventBus.emit('add', {
          summary: DEFAULT_TOAST_SUMMARY_DICTIONARY[message.severity] || 'Info',
          life: DEFAULT_TOAST_DURATION_IN_MILLISECONDS,
          ...message,
        });
      },
      remove: (message) => {
        ToastEventBus.emit('remove', message);
      },
      removeGroup: (group) => {
        ToastEventBus.emit('remove-group', group);
      },
      removeAllGroups: () => {
        ToastEventBus.emit('remove-all-groups');
      },
    };

    app.config.globalProperties.$toast = ToastService;
    app.provide(PrimeVueToastSymbol, ToastService);
  },
};
