import ekozAxios from './instance/axios';

export const createTextToSpeech = ({
  userId,
  text,
}) => {
  const formData = new FormData();

  formData.append('user_id', userId);
  formData.append('text', text);

  return ekozAxios.request({
    method: 'post',
    url: '/manual_voice/generate_tts.php',
    data: formData,
  });
};
