<template>
  <section class="p-5 text-center">
    <h1 class="text-8xl mb-0">
      {{ errorCode }}
    </h1>
    <p class="text-2xl">{{ errorText }}</p>
    <div>
      <router-link to="/">
        <Button>
          Homepage
        </Button>
    </router-link>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: Number,
      default: 500,
    },
    errorText: {
      type: String,
      default: 'There was an error with your request.',
    },
  },
};
</script>
