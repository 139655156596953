<template>
  <Card class="analytics-card">
    <template #title>
      <Skeleton
        width="200px"
        height="21px"
      />
    </template>
    <template #content>
      <Skeleton
        class="mb-2"
        width="100px"
        height="29px"
      />
      <Skeleton
        width="120px"
        height="17px"
      />
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.analytics-card {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    border-radius: 12px 0 0 12px;
    background-color: var(--gray-400)
  }
}
</style>
