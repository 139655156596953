<template>
  <div class="field flex flex-column gap-1">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>
