import ekozAxios from './instance/axios';

export const readAds = ({
  adId,
  organizationId,
  programId,
}) => ekozAxios.request({
  method: 'get',
  url: '/content/get_contents.php',
  params: {
    id: adId,
    org_id: organizationId,
    program_id: programId,
  },
});

export const pauseAd = ({
  adId,
  action,
}) => {
  const formData = new FormData();

  formData.append('ad_id', adId);
  formData.append('action', action);

  return ekozAxios.request({
    method: 'post',
    url: '/content/pause_content.php',
    data: formData,
  });
};
