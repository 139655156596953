<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{ errors, value: fieldValue, handleChange }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
      >
        {{ fieldLabel }}
      </label>
      <MultiSelect
        :modelValue="fieldValue"
        @update:modelValue="handleChange"
        :inputId="fieldId"
        v-bind="{
          ...defaultAttrs,
          ...$attrs,
        }"
        :invalid="errors.length > 0"
      />
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    defaultAttrs() {
      return {
        optionLabel: 'label',
        optionValue: 'value',
      };
    },
  },
};
</script>
