export const parseMessageFromError = (error, defaultMessage = 'There was a server error.') => {
  if (error && error.data && error.data.error_message) {
    return error.data.error_message;
  }

  if (error && error.data && error.data.message) {
    return error.data.message;
  }

  if (error && error.message) {
    return error.message;
  }

  return defaultMessage;
};
