<template>
<Card>
  <template #content>
    <div class="grid">
      <div class="col-12 sm:col sm:flex-grow-0">
        <div class="flex flex-column align-items-center">
          <Avatar
            class="profile-avatar text-3xl"
            :label="avatarLabel"
            shape="circle"
          />
        </div>
      </div>
      <div class="col-12 sm:col sm:flex-grow-1">
        <div class="flex flex-column h-full justify-content-center">
          <p class="text-3xl my-0">{{ username }}</p>
          <p class="mt-1 mb-0">{{ email }}</p>
        </div>
      </div>
      <div class="col-12 sm:col sm:flex-grow-0">
        <div class="flex flex-column h-full justify-content-center">
          <Button
            class="justify-content-center"
            severity="contrast"
            outlined
            @click="$emit('logout')"
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  </template>
</Card>
</template>

<script>
export default {
  props: {
    avatarLabel: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    username: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    email: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-avatar {
  width: 120px;
  height: 120px;
}
</style>
