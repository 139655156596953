<template>
  <Dialog
    class="with-footer-gradient voiceprint-dialog"
    :visible="visible"
    dismissableMask
    modal
    :pt="{
      root: {
        class: 'p-dialog-maximized',
      },
      header: {
        class: 'p-2',
      },
      footer: {
        class: 'block',
        style: {
          'padding-top': '1.5rem',
        }
      }
    }"
    :closable="false"
    @update:visible="onClose"
  >
    <Card class="wizard-container">
      <template #content>
        <div class="grid">
          <div class="col-12">
            <div>
              <h3 class="mt-0 text-2xl font-bold">{{ header }}</h3>
              <VoiceprintCard
                :isLoading="myUserStore.myVoiceprintIsLoading"
                :src="myUserStore.myVoiceprint"
                :score="myUserStore.myVoiceprintScore"
                :isScoreLoading="myUserStore.myVoiceprintScoreIsLoading"
              >
                <template #noVoiceprint>
                  Add a sample file to create your first voiceprint.
                </template>
              </VoiceprintCard>
            </div>
            <Divider />
            <div class="mt-4">
              <h3 class="mt-0 text-xl">
                Voice Samples
                <i
                  v-tooltip.top="`We recommend including at least 2 or more
                    samples to provide the most accurate result.`"
                  class="ml-1 pi pi-info-circle text-indigo-400"
                />
              </h3>

              <!-- must use hidden class to hide elements in this slot due to vue3 core issue https://github.com/vuejs/core/issues/5657
              editing a pinia state variable is causing this slot to re-render and when the
              state is used in a v-if vue is throwing
              "Cannot read properties of null (reading 'insertBefore')"
              NOTE: this only happens in production -->
              <span
                :class="{
                  'mt-2': true,
                  'hidden': myUserStore.myVoiceSamples.length > 0
                }"
              >
                No voice samples found
              </span>
              <ul
                :class="{
                  'list-none px-0': true,
                  'hidden': myUserStore.myVoiceSamples.length === 0
                }"
              >
                <li
                  class="mt-3"
                  v-for="sample in myUserStore.myVoiceSamples"
                  :key="sample.id"
                >
                  <VoiceSampleCard
                    :sample="sample"
                    @deleteSample="onRemoveSampleClick"
                  />
                </li>
              </ul>

              <div class="mt-3">
                <Button
                  class="w-full"
                  label="Add Voice Sample"
                  outlined
                  @click="addVoiceSampleDialogData.visible = true"
                />

                <AddVoiceSampleDialog
                  v-model:visible="addVoiceSampleDialogData.visible"
                  @submit="onAddVoiceSampleSubmit"
                  :isSubmitting="addVoiceSampleDialogData.isSubmitting"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <Card
              class="bg-dark-grey slider-controls-card"
              :pt="{
                body: {
                  class: 'slider-controls-card-body'
                }
              }"
            >
              <template #content>
                <div class="text-lg text-white">Audio settings</div>
                <Card
                  class="bg-grey text-white mt-3"
                  :pt="{
                    body: {
                      class: 'px-3 py-3',
                    },
                  }"
                  v-for="item in sliders"
                  :key="item.name"
                >
                  <template #content>
                    <div class="flex justify-content-between">
                      <label
                        className="text-sm"
                        :for="item.formId"
                      >
                        <i
                          v-if="item.tooltip"
                          class="pi pi-info-circle text-indigo-400 mr-1"
                          v-tooltip.top="item.tooltip"
                        />
                        {{ item.name }}
                      </label>
                      <span class="pl-2 text-sm">
                        <template v-if="item.transformFunction">
                          {{ item.transformFunction(form[item.formField]) }}
                        </template>
                        <template v-else>
                          {{ form[item.formField] }}
                        </template>
                        {{ item.labelUnit }}
                      </span>
                    </div>
                    <div class="px-2 pt-3 pb-2">
                      <Slider
                        :id="item.formId"
                        v-model="form[item.formField]"
                        :disabled="isUpdatingSettings"
                        :min="item.min || 0"
                        :max="item.max || 100"
                        :step="item.step || 1"
                      />
                    </div>
                  </template>
                </Card>

                <Card
                  class="bg-grey text-white mt-3"
                  :pt="{
                    body: {
                      class: 'px-3 pt-3 pb-2',
                    },
                  }"
                >
                  <template #content>
                    <BaseDropdown
                      v-model="form.accent"
                      fieldId="accent"
                      fieldName="accent"
                      fieldLabel="Accent"
                      placeholder="Choose an accent"
                      :options="ACCENT_OPTIONS"
                      :pt="{
                        input: {
                          class: 'px-2 py-1'
                        }
                      }"
                      :fieldLabelAttrs="{
                        className: 'text-sm',
                      }"
                      :disabled="isUpdatingSettings"
                      filter
                    >
                      <template #value="slotProps">
                        <div class="flex align-items-center">
                          {{ slotProps.value || slotProps.placeholder }}
                          <Chip
                            v-if="accentIsBeta"
                            class="ml-1 text-xs py-0 bg-indigo-100"
                            label="Beta"
                          />
                        </div>
                      </template>
                      <template #option="slotProps">
                        {{ slotProps.option.label }}
                        <Chip
                          v-if="slotProps.option.isBeta"
                          class="ml-2 text-xs py-0 bg-indigo-100"
                          label="Beta"
                        />
                      </template>
                    </BaseDropdown>

                    <BaseDropdown
                      v-model="form.gender"
                      fieldId="gender"
                      fieldName="gender"
                      fieldLabel="Gender"
                        placeholder="Choose a gender"
                        :options="[
                          { label: 'Male', value: 'Male'},
                          { label: 'Female', value: 'Female'},
                          { label: 'Neutral', value: 'Neutral'},
                        ]"
                        :pt="{
                          input: {
                            class: 'px-2 py-1'
                          }
                        }"
                        :fieldLabelAttrs="{
                          className: 'text-sm',
                        }"
                        :disabled="isUpdatingSettings"
                    />
                  </template>
                </Card>

                <Button
                  class="mt-4 w-full"
                  size="small"
                  :outlined="!voiceSettingsHaveChanged"
                  raised
                  label="Update settings"
                  :loading="isUpdatingSettings"
                  @click="onUpdateSettings"
                />
              </template>
            </Card>
          </div>
        </div>

        <ConfirmDialog
          v-model:visible="deleteVoiceSampleDialog.visible"
          header="Confirm Delete"
          :isSubmitting="deleteVoiceSampleDialog.isSubmitting"
          @confirm="onRemoveVoiceSampleConfirm"
        >
          <p>
            Are you sure you want to delete this voice sample?
          </p>
          <p>
            Upon deletion, your voiceprint will be updated
            with the remaining samples.
          </p>
        </ConfirmDialog>

        <ConfirmDialog
          v-model:visible="unsavedChangesDialogIsVisible"
          header="You have unsaved changes"
          @confirm="() => {
            this.voiceSettingsHaveChanged = false;
            this.unsavedChangesDialogIsVisible = false;
            this.onClose();
          }"
          confirmButtonLabel="Leave"
          cancelButtonLabel="Stay"
        >
          <p>
            There are unsaved changes to your voiceprint settings.
            Are you sure you want to leave?
          </p>
        </ConfirmDialog>
      </template>
    </Card>

    <template #footer>
      <div class="wizard-container flex justify-content-end">
        <Button
          text
          plain
          label="Accept Your Voiceprint"
          :disabled="isCloseDisabled"
          @click="onClose"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import * as api from '@/api';
import VoiceSampleCard from '@/components/voiceSampleCard';
import VoiceprintCard from '@/components/voiceprintCard';
import ConfirmDialog from '@/components/confirmDialog';
import { ACCENT_OPTIONS } from '@/constants';
import { parseMessageFromError } from '@/utils/errors';
import AddVoiceSampleDialog from './components/addVoiceSampleDialog';

const SLIDERS = [
  {
    name: 'Charisma',
    formId: 'slider-charisma',
    formField: 'voice_stability',
    tooltip: 'This determines the emotional range of the voice. Increasing the slider, adds a broader range of emotion.',
    labelUnit: '%',
  },
  {
    name: 'Vocal Clarity',
    formId: 'slider-vocal-clarity',
    formField: 'voice_similarity_boost',
    tooltip: 'This determines how close the voice should match to the original audio samples.',
    labelUnit: '%',
  },
  {
    name: 'Style Exaggeration',
    formId: 'slider-style-exaggeration',
    formField: 'style_exaggeration',
    tooltip: 'This exaggerates the style of the original audio samples. 0 means no exaggeration.',
    labelUnit: '%',
  },
  {
    name: 'Pitch',
    formId: 'slider-pitch-change',
    formField: 'pitch_change',
    min: 90,
    max: 110,
    step: 0.1,
    // transform values values of range 90 - 110 to -10 - 10
    transformFunction: (value) => Math.round((value - 100) * 100) / 100,
  },
  {
    name: 'Speed',
    formId: 'slider-speed-change',
    formField: 'speed_change',
    min: 0.9,
    max: 1.1,
    step: 0.01,
  },
];

export default {
  props: {
    visible: Boolean,
    header: {
      type: String,
      default: 'Manage Voiceprint',
    },
  },
  components: {
    VoiceSampleCard,
    VoiceprintCard,
    ConfirmDialog,
    AddVoiceSampleDialog,
  },
  data() {
    return {
      formUnwatch: null,
      unsavedChangesDialogIsVisible: false,
      ACCENT_OPTIONS,
      sliders: SLIDERS,
      form: {
        voice_stability: 30,
        voice_similarity_boost: 70,
        style_exaggeration: 0,
        pitch_change: 100,
        speed_change: 1,
        accent: null,
        gender: null,
      },
      voiceSettingsHaveChanged: false,
      addVoiceSampleDialogData: {
        visible: false,
        isSubmitting: false,
      },
      deleteVoiceSampleDialog: {
        visible: false,
        isSubmitting: false,
        voiceSample: null,
      },
      isUpdatingSettings: false,
    };
  },
  computed: {
    ...mapStores(useMyUserStore),
    isCloseDisabled() {
      return this.deleteVoiceSampleDialog.isSubmitting
        || this.isUpdatingSettings
        || this.addVoiceSampleDialogData.isSubmitting;
    },
    accentIsBeta() {
      if (!this.form.accent) return false;

      const chosenAccent = ACCENT_OPTIONS.find((item) => item.value === this.form.accent);

      return chosenAccent && chosenAccent.isBeta === true;
    },
  },
  watch: {
    visible: {
      immediate: true,
      async handler(newVal) {
        // if dialog is opening, reset state
        if (newVal === true) {
          // TODO - cleanup the data preload code. This may be fixed when we
          // make a new page for managing voiceprint
          this.getMyVoiceprint();
          this.getMyVoiceSamples();

          try {
            await this.myUserStore.getMyVoiceSettings();
            this.initializeForm();
          } catch (error) {
            const message = parseMessageFromError(error, 'Error loading voiceprint settings.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }
      },
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  methods: {
    beforeUnload(event) {
      if (this.voiceSettingsHaveChanged) {
        event.preventDefault();
        // eslint-disable-next-line no-alert
        alert('There are unsaved changes to your voice settings. Are you sure you want to leave?');
      }
    },
    initializeForm() {
      this.form = {
        ...this.form,
        voice_stability: Math.round((1 - this.myUserStore.voiceStability) * 100),
        voice_similarity_boost: Math.round(this.myUserStore.voiceSimilarityBoost * 100),
        style_exaggeration: Math.round(this.myUserStore.voiceStyleExaggeration * 100),
        pitch_change: this.myUserStore.voicePitchChange,
        speed_change: this.myUserStore.voiceSpeedChange,
        accent: this.myUserStore.voiceAccent,
        gender: this.myUserStore.voiceGender,
      };
      this.voiceSettingsHaveChanged = false;

      if (this.formUnwatch) this.formUnwatch();
      this.formUnwatch = this.$watch('form', function formWatch() {
        this.voiceSettingsHaveChanged = true;
      }, {
        deep: true,
      });
    },
    onClose() {
      if (this.isCloseDisabled) return;

      if (this.voiceSettingsHaveChanged) {
        this.unsavedChangesDialogIsVisible = true;
        return;
      }

      this.$emit('update:visible', false);
    },
    onRemoveSampleClick(voiceSample) {
      this.deleteVoiceSampleDialog = {
        ...this.deleteVoiceSampleDialog,
        visible: true,
        voiceSample,
      };
    },
    async getMyVoiceprint() {
      try {
        if (this.myUserStore.voiceId && !this.myUserStore.myVoiceprint) {
          await this.myUserStore.getMyVoiceprint();
        }
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating voiceprint.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async getMyVoiceSamples() {
      try {
        await this.myUserStore.getMyVoiceSamples();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading voice samples.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async getMyVoiceprintScore() {
      try {
        await this.myUserStore.getMyVoiceprintScore();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading voiceprint score.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onRemoveVoiceSampleConfirm() {
      try {
        this.deleteVoiceSampleDialog.isSubmitting = true;

        await api.deleteAudioSample(this.deleteVoiceSampleDialog.voiceSample.id);
        await this.myUserStore.getMyVoiceSamples();

        if (this.myUserStore.voiceId) {
          await this.myUserStore.getMyVoiceSettings(this.myUserStore.userId);
          await api.updateUserVoicePrint({
            userId: this.myUserStore.userId,
            sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
            accent: this.myUserStore.voiceAccent,
            gender: this.myUserStore.voiceGender,
          });
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
        }

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully deleted voice sample.',
        });

        this.deleteVoiceSampleDialog.visible = false;
        this.deleteVoiceSampleDialog.voiceSample = null;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error deleting voice sample.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.deleteVoiceSampleDialog.isSubmitting = false;
      }
    },
    async onUpdateSettings() {
      try {
        this.isUpdatingSettings = true;

        await api.updateUserVoiceSettings({
          userId: this.myUserStore.userId,
          voiceStability: Math.round((1 - (this.form.voice_stability / 100)) * 100) / 100,
          voiceSimilarityBoost: this.form.voice_similarity_boost / 100,
          styleExaggeration: this.form.style_exaggeration / 100,
          pitchChange: this.form.pitch_change,
          speedChange: this.form.speed_change,
        });
        if (this.myUserStore.myVoiceprint) {
          await api.updateUserVoicePrint({
            userId: this.myUserStore.userId,
            sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
            accent: this.form.accent,
            gender: this.form.gender,
          });
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
        }
        // refresh voice settings data
        await this.myUserStore.getMyUser();
        await this.myUserStore.getMyVoiceSettings();

        this.initializeForm();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated voice settings',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating settings.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isUpdatingSettings = false;
      }
    },
    async onAddVoiceSampleSubmit(files) {
      try {
        this.addVoiceSampleDialogData.isSubmitting = true;
        // if user has a voiceprint and is uploaded new files
        //  upload files
        //  refresh voice sample files
        //  set user voice settings
        //  read current voice settings
        //  update user voiceprint
        //  refresh my user data
        if (this.myUserStore.voiceId && files.length > 0) {
          await api.createAudioSamples(this.myUserStore.userId, files);
          await this.myUserStore.getMyVoiceSamples();

          await api.updateUserVoiceSettings({
            userId: this.myUserStore.userId,
            voiceStability: Math.round((1 - (this.form.voice_stability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.form.voice_similarity_boost / 100,
            styleExaggeration: this.form.style_exaggeration / 100,
            pitchChange: this.form.pitch_change,
            speedChange: this.form.speed_change,
          });
          await this.myUserStore.getMyVoiceSettings(this.myUserStore.userId);
          await api.updateUserVoicePrint({
            userId: this.myUserStore.userId,
            sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
            accent: this.myUserStore.voiceAccent,
            gender: this.myUserStore.voiceGender,
          });
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
          await this.myUserStore.getMyUser();
        } else if (!this.myUserStore.voiceId && files.length > 0) {
          // if user does not have a voiceprint, create one
          //  set user voice settings
          //  create voiceprint with files
          //  refresh voice sample files
          //  refresh my user data
          await api.updateUserVoiceSettings({
            userId: this.myUserStore.userId,
            voiceStability: Math.round((1 - (this.form.voice_stability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.form.voice_similarity_boost / 100,
            styleExaggeration: this.form.style_exaggeration / 100,
            pitchChange: this.form.pitch_change,
            speedChange: this.form.speed_change,
          });
          await api.createUserVoicePrint({
            userId: this.myUserStore.userId,
            files,
            accent: this.form.accent,
            gender: this.form.gender,
          });
          await this.myUserStore.getMyVoiceSamples();
          await this.myUserStore.getMyVoiceprint();
          this.getMyVoiceprintScore();
          await this.myUserStore.getMyUser();
        }

        this.addVoiceSampleDialogData.visible = false;

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully added voice sample files',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding voice sample files.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.addVoiceSampleDialogData.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.voiceprint-dialog {
  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    padding-right: 300px;
  }
}

</style>

<style lang="scss" scoped>
@import "@/styles/variables";

.slider-controls-card {
  position: relative;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 300px;
  }

  :deep(.slider-controls-card-body) {
    position: relative;
    overflow-y: auto;

    >* {
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      height: 104px;
      background-color: var(--primary-color);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}
</style>
