<template>
  <CreateOrganizationDialog
    :visible="isCreateOrganizationDialogVisible"
    :isSubmitting="isCreateOrganizationSubmitting"
    avatarIcon="pi pi-microphone"
    @submit="onSubmitOrganization"
  />
  <ProgramFormDialog
    v-model:visible="isCreateProgramDialogVisible"
    @update:visible="($event) => {
      if ($event === false) onCancelProgram();
    }"
    :categoryOptions="categoriesStore.categoryOptions"
    :sensitiveCategoryOptions="categoriesStore.sensitiveCategoryOptions"
    initialPreviousButtonText="Skip for now"
    @submit="onSubmitProgram"
  />
  <VoiceprintWizard
    v-model:visible="isCreateVoiceprintDialogVisible"
    initialPreviousButtonText="Skip"
    @update:visible="($event) => {
      if ($event === false) onCancelVoiceprint();
    }"
  />
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore, useProgramsStore, useCategoriesStore } from '@/stores';
import CreateOrganizationDialog from '@/components/createOrganizationDialog';
import ProgramFormDialog from '@/components/programFormDialog';
import VoiceprintWizard from '@/components/voiceprintWizard/index';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';

const generateProgramSkipKey = (organizationId) => `skip_program_create_org_${organizationId}`;

const generateVoiceprintSkipKey = (userId) => `skip_voiceprint_create_user_${userId}`;

export default {
  components: {
    CreateOrganizationDialog,
    ProgramFormDialog,
    VoiceprintWizard,
  },
  computed: {
    ...mapStores(useMyUserStore, useProgramsStore, useCategoriesStore),
  },
  data() {
    return {
      isCreateOrganizationDialogVisible: false,
      isCreateOrganizationSubmitting: false,

      isCreateProgramDialogVisible: false,
      isCreateProgramSubmitting: false,

      isCreateVoiceprintDialogVisible: false,
      isCreateVoiceprintSubmitting: false,
    };
  },
  async mounted() {
    await this.auditAccountSetup();
  },
  methods: {
    async auditAccountSetup() {
      try {
        // check if user has org
        const userHasOrganization = !!this.myUserStore.myOrganization;

        // if not then show create org dialog
        if (!userHasOrganization) {
          this.isCreateOrganizationDialogVisible = true;
          return;
        }

        this.isCreateOrganizationDialogVisible = false;

        const organizationId = this.myUserStore.myOrganization.id;

        const programSkipKey = generateProgramSkipKey(organizationId);
        const skipProgramCreate = !!localStorage.getItem(programSkipKey);
        if (skipProgramCreate) {
          this.isCreateProgramDialogVisible = false;
        } else {
          // check if user org has atleast 1 program
          await this.programsStore.getPrograms({
            organizationId,
          });
          const programs = this.programsStore
            .getProgramsByOrganizationId(organizationId);
          const organizationHasPrograms = programs.length > 0;

          // if not then show create program dialog
          if (!organizationHasPrograms) {
            this.isCreateProgramDialogVisible = true;
            return;
          }

          this.isCreateProgramDialogVisible = false;
        }

        // check if user has voiceprint
        const voiceprintSkipKey = generateVoiceprintSkipKey(this.myUserStore.userId);
        const skipVoiceprintCreate = !!localStorage.getItem(voiceprintSkipKey);
        if (skipVoiceprintCreate) {
          this.isCreateVoiceprintDialogVisible = false;
        } else {
          // check if user has voiceprint
          const userHasVoiceprint = !!this.myUserStore.voiceId;

          // if not then show create voiceprint dialog
          if (!userHasVoiceprint) {
            this.isCreateVoiceprintDialogVisible = true;
            return;
          }

          this.isCreateVoiceprintDialogVisible = false;
        }
      } catch (error) {
        const message = parseMessageFromError(error, 'Error setting up account. Please refresh the page and try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitOrganization({
      organizationForm,
      invitedUsers,
    }) {
      try {
        const userHasSkippedForm = organizationForm.name === '';

        this.isCreateOrganizationSubmitting = true;

        const res = await api.createOrganization({
          name: organizationForm.name,
        });
        const organizationId = res.organization_id;
        await api.updateUser({
          userId: this.myUserStore.myUser.id,
          organizationId,
        });

        if (!userHasSkippedForm) {
          this.$toast.add({
            severity: 'success',
            detail: 'Successfully created organization',
          });
        }

        if (invitedUsers.length > 0) {
          try {
            await Promise.all(invitedUsers.map((user) => api.inviteUser({
              email: user.email,
              organizationId,
            })));
          } catch (error) {
            const message = parseMessageFromError(error, 'Organization created, but failed to invite users. Please visit the organization page and re-invite users.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }

        await this.myUserStore.getMyUser();
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateOrganizationSubmitting = false;
      }
    },
    async onSubmitProgram({ programForm }) {
      try {
        this.isCreateProgramSubmitting = true;

        const targetAgeRanges = programForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const adPreferences = programForm.adPreferences.map((item) => ({
          adRunSlot: item.adRunSlot,
          length: item.adLength,
          min_cpm: item.minimumCpm,
        }));

        await api.createProgram({
          organizationId: this.myUserStore.myOrganization.id,
          name: programForm.name,
          description: programForm.showDescription,
          contactName: programForm.hostName,
          contactEmail: programForm.hostEmail,
          averageListeners: programForm.averageMonthlyDownloads,
          tapProgramId: programForm.tapProgramId,
          targetGender: programForm.targetGender,
          targetAgeRanges,
          adPreferences,
          categories: programForm.categories,
          blacklistedCategories: programForm.categoriesBlacklist,
          approvalBehavior: programForm.approvalBehavior,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created podcast',
        });
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding podcast.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateProgramSubmitting = false;
      }
    },
    async onCancelProgram() {
      try {
        localStorage.setItem(generateProgramSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onCancelVoiceprint() {
      try {
        const userHasVoiceprint = !!this.myUserStore.voiceId;

        if (!userHasVoiceprint) {
          localStorage.setItem(generateVoiceprintSkipKey(this.myUserStore.userId), true);
        }

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>
