import _sortBy from 'lodash/sortBy';

const ACCENT_AMERICAN = 'American';

const ACCENT_VALUES = [
  ACCENT_AMERICAN,
  'Australian',
  'British',
  'Canadian',
  'French',
  'Georgian',
  'German',
  'Chinese',
  'Indian',
  'Irish',
  'Japanese',
  'Scottish',
  'Spanish',
];

export const ACCENT_OPTIONS = _sortBy(
  ACCENT_VALUES.map((item) => ({
    label: item,
    value: item,
    isBeta: item !== ACCENT_AMERICAN,
  })),
  (item) => (typeof item.value === 'string' ? item.value.toLowerCase() : item.value),
);

export const DEFAULT_ACCENT = ACCENT_AMERICAN;

export const DEFAULT_VOICE_STABILITY = 0.7; // range is 0 - 1
export const DEFAULT_VOICE_SIMILARITY_BOOST = 0.7; // range is 0 - 1
export const DEFAULT_VOICE_STYLE_EXAGERATION = 0; // range is 0 - 1
export const DEFAULT_VOICE_PITCH_CHANGE = 100; // range is 80 - 120
export const DEFAULT_VOICE_SPEED_CHANGE = 1; // range is 0.5 - 2

export const DEFAULT_VOICEPRINT_SAMPLE_TEXT = `Hey everyone, before we jump back into our discussion, let me quickly share something that’s revolutionizing the way we handle ads here on the podcast. It’s called Echos.ai.
Imagine capturing your unique voice and then, using AI, being able to create ads without ever needing to sit down and record each one manually. That’s exactly what Echos.ai offers. With just your voice print, Echos.ai can generate and deliver ads that sound just like me, maintaining that personal touch you’re used to.
No more interruptions with prerecorded ads, and the best part? It gives me more time to focus on creating content that you love, while also stepping into larger ad campaigns effortlessly.
So, if you enjoy the seamless experience of hearing my voice in ads, know that it’s all thanks to Echos.ai, ensuring you get the best listening experience. Now, let’s dive back into our topic.`;
