<template>
  <div class="flex flex-wrap gap-3">
    <div
      v-for="option in GENDER_OPTIONS"
      :key="option.value"
      class="flex align-items-center"
    >
      <RadioButton
        v-model="value"
        :inputId="`gender-${option.value}`"
        :value="option.value"
      />
      <label
        class="ml-1"
        :for="`gender-${option.value}`"
      >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
import { GENDER_OPTIONS } from '@/constants';

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
      default: 'Gender',
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {
      GENDER_OPTIONS,
    };
  },
};
</script>
