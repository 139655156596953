<template>
  <Message
    class="notification-message"
    :severity="severity || 'secondary'"
    @close="$emit('dismissMessage')"
  >
    <div class="mb-3">{{title}}</div>
    <div class="font-normal mb-3">{{content}}</div>
    <div v-if="link">
      <a :href="link">Link</a>
    </div>
  </Message>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    link: String,
    severity: String,
  },
};
</script>

<style lang="scss" scoped>
  .notification-message {
    :deep(.p-message-wrapper) {
      margin: 12px;
      align-items: flex-start;
    }
    :deep(.p-message-icon) {
      display: none;
    }
  }
</style>
