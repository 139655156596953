import ekozAxios from './instance/axios';

export const readImpressions = ({
  campaignId,
  adId,
  startDate,
  endDate,
  eventType,
  groupBy,
}) => ekozAxios.request({
  method: 'get',
  url: '/analytics/get_impressions.php',
  params: {
    campaign_id: campaignId,
    ad_id: adId,
    start_date: startDate,
    end_date: endDate,
    event_type: eventType,
    group_by: groupBy,
  },
});
