<template>
  <Dialog
    class="with-footer-gradient"
    :visible="visible"
    dismissableMask
    modal
    :pt="{
      root: 'p-dialog-maximized',
      footer: {
        class: 'block',
        style: {
          'padding-top': '1.5rem',
        }
      }
    }"
    :showHeader="false"
    @update:visible="onToggle"
  >
    <div
      ref="dialogBody"
      class="wizard-container pt-3"
    >
      <!-- Step 1 -->
      <Card v-if="activeStep === 1">
        <template #content>
          <WizardStepHeader
            :stepNumber="1"
            title="Podcast sign up"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            :validationSchema="importSchema"
            as="div"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="import-program-form"
              @submit.prevent="handleSubmit($event, onSubmitImport)"
            >
              <BaseFieldContainer>
                <VeeField
                  name="importUrl"
                  v-model="importForm.importUrl"
                  v-slot="{ errors, field }"
                >
                  <label
                    for="importUrl"
                    :class="{ 'text-red-400': errors.length > 0}"
                  >
                    Podcast Url <small class="text-gray-400">(optional)</small>
                  </label>

                  <small id="importUrlHelp">
                    Enter your apple podcast url and click "Import".
                    This will try and import your settings to the following form.
                  </small>
                  <InputGroup>
                    <InputText
                      id="importUrl"
                      v-bind="field"
                      :invalid="errors.length > 0"
                      aria-describedby="importUrlHelp"
                    />
                    <Button
                      type="submit"
                      label="Import"
                      :loading="isImportSubmitting"
                    />
                  </InputGroup>
                  <small
                    v-if="errors.length > 0"
                    class="text-red-400"
                  >
                    {{ errors[0] }}
                  </small>
                </VeeField>
              </BaseFieldContainer>
            </form>
          </VeeForm>

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepOneSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="program-form"
              @submit.prevent="handleSubmit($event, onSubmitStepOne)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseInput
                    v-model="stepOneForm.name"
                    fieldId="name"
                    fieldName="name"
                    fieldLabel="Podcast Name"
                  />
                </div>
                <div class="col-12 md:col-6">
                  <BaseInput
                    v-model="stepOneForm.hostName"
                    fieldId="hostName"
                    fieldName="hostName"
                    fieldLabel="Host Name"
                  />
                </div>
                <div class="col-12 md:col-6">
                  <BaseInput
                    v-model="stepOneForm.hostEmail"
                    fieldId="hostEmail"
                    fieldName="hostEmail"
                    fieldLabel="Host Email"
                    type="email"
                  />
                </div>

                <div class="col-12">
                  <Divider />

                  <HostingPlatformInput
                    v-model:tapProgramId="stepOneForm.tapProgramId"
                  />

                  <Divider />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseInputNumber
                    v-model="stepOneForm.averageMonthlyDownloads"
                    fieldId="averageMonthlyDownloads"
                    fieldName="averageMonthlyDownloads"
                    fieldLabel="Average monthly downloads"
                    placeholder="Type number"
                  />
                </div>

                <div class="col-12">
                  <BaseTextarea
                    v-model="stepOneForm.showDescription"
                    fieldId="showDescription"
                    fieldName="showDescription"
                    fieldLabel="Show Description"
                    helperText="Add a description of your podcast.
                      This will be used as context in your generated ads."
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 2 -->
      <template v-else-if="activeStep === 2">
        <Card
          class="cursor-pointer"
          @click="activeStep = 1"
        >
          <template #content>
            <div class="flex justify-content-between">
              <div class="flex align-items-center">
                <i
                  class="pi pi-check-circle mr-2 text-green-400"
                  style="font-size: 18px;"
                />
                <h2 class="text-base m-0 inline">Step 1</h2>
              </div>
              <i
                class="pi pi-angle-down"
                style="font-size: 18px;"
              />
            </div>
          </template>
        </Card>
      <Card class="mt-3">
        <template #content>
          <WizardStepHeader
            :stepNumber="2"
            title="Podcast sign up"
          />

          <p>
            For your show, please check all that apply
          </p>

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepTwoSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="program-form"
              @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseFieldContainer>
                    <div class="mb-2 font-bold">
                      Target Gender
                    </div>
                    <GenderInput
                      v-model="stepTwoForm.targetGender"
                    />
                  </BaseFieldContainer>

                  <Divider />
                </div>

                <div class="col-12">
                  <BaseFieldContainer>
                    <div class="mb-2 font-bold">Target Age</div>
                    <TargetAgeInput
                      v-model="stepTwoForm.targetAgeRanges"
                    />
                  </BaseFieldContainer>
                </div>

                <div class="col-12">
                  <Divider />

                  <CategoryInput
                    fieldLabel="Relevant Categories"
                    fieldName="categories"
                    :categoryOptions="categoryOptions"
                    v-model="stepTwoForm.categories"
                    helperText="Choose all categories that describe the content of your podcasts."
                  />
                </div>

                <div class="col-12">
                  <Divider />

                  <CategoryInput
                    fieldLabel="Exclude Advertising Categories"
                    fieldName="categoriesBlacklist"
                    :categoryOptions="sensitiveCategoryOptions"
                    v-model="stepTwoForm.categoriesBlacklist"
                    helperText="These are the advertising categories
                      you are unwilling to work with."
                  />

                  <Divider />
                </div>

                <div class="col-12">
                  <BaseCheckbox
                    v-model="stepTwoForm.approvalBehavior"
                    fieldId="approvalBehavior"
                    fieldName="approvalBehavior"
                    fieldLabel="Automatically approve ads"
                    helperText="<p>
                        This option determines if campaigns that
                        match your podcast should automatically be approved
                        and run without user intervention.
                      </p>
                      <p>
                        If you opt out of this feature,
                        you will be prompted to approve or decline campaigns before the
                        campaign can start.
                      </p>"
                    binary
                    trueValue="automatic"
                    falseValue="manual"
                  />
                </div>

                <div class="col-12">
                  <div class="field flex flex-column">
                    <div class="font-bold">Ad preferences</div>
                    <ul class="list-none p-0 m-0">
                      <li
                        class="mt-4"
                        v-for="(item, index) in stepTwoForm.adPreferences"
                        :key="index"
                      >
                        <div class="grid">
                          <div class="col sm:py-1">
                            <Dropdown
                              class="w-full"
                              v-model="item.adRunSlot"
                              placeholder="Choose when the ad should run"
                              :options="AD_RUN_SLOT_OPTIONS"
                              optionLabel="label"
                              optionValue="value"
                            />
                          </div>
                          <div class="col flex sm:py-1">
                            <Dropdown
                              class="flex-grow-1"
                              v-model="item.adLength"
                              placeholder="Choose an ad length"
                              :options="AD_LENGTH_OPTIONS"
                              optionLabel="label"
                              optionValue="value"
                            />
                          </div>
                          <div class="col flex sm:py-1">
                            <InputNumber
                              class="w-full"
                              v-model="item.minimumCpm"
                              placeholder="Enter minimum cpm for ad"
                              mode="currency"
                              currency="USD"
                            />
                            <Button
                              class="ml-2"
                              icon="pi pi-trash"
                              @click="onDeletePreference(index)"
                            />
                          </div>
                        </div>
                        <div class="grid mt-0 sm:mt-3">
                          <div
                            v-if="item.adRunSlot && item.adLength"
                            class="col-12 sm:py-0"
                          >
                            <BidWarning
                              class="mb-2"
                              :userType="USER_TYPE_HOST"
                              :adRunSlot="item.adRunSlot"
                              :adLength="item.adLength"
                              :cpm="item.minimumCpm"
                            />
                            <BidRecommendation
                              :userType="USER_TYPE_HOST"
                              :adRunSlot="item.adRunSlot"
                              :adLength="item.adLength"
                            />
                          </div>
                        </div>

                        <Divider class="mb-5"/>
                      </li>
                    </ul>

                    <Button
                      class="mt-3"
                      outlined
                      icon="pi pi-plus"
                      label="Add Preference"
                      @click="onAddPreference"
                    />
                  </div>
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>
      </template>

      <ConfirmDialog
        v-model:visible="unsavedChangesDialogIsVisible"
        header="You have unsaved changes"
        @confirm="() => {
          this.formHasChanges = false;
          this.unsavedChangesDialogIsVisible = false;
          this.onToggle(false);
        }"
        confirmButtonLabel="Leave"
        cancelButtonLabel="Stay"
      >
        <p>
          There are unsaved changes to your podcast.
          Are you sure you want to leave?
        </p>
      </ConfirmDialog>
    </div>

    <template #footer>
      <div class="wizard-container flex justify-content-end">
        <Button
          text
          plain
          :label="previousButtonText"
          :loading="isSubmitting"
          @click="onClickPrevious"
        />
        <Button
          class="ml-2"
          :label="nextButtonText"
          type="submit"
          form="program-form"
          :loading="isSubmitting"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { array, object, string } from 'yup';
import {
  AD_RUN_SLOT_OPTIONS,
  AD_LENGTH_OPTIONS,
  AD_LENGTH_30_SECONDS,
  AGE_GROUP_OPTIONS,
  AD_APPROVAL_BEHAVIOR_MANUAL,
  AD_APPROVAL_BEHAVIOR_AUTOMATIC,
  GENDER_ALL,
  AD_RUN_SLOT_PRE_ROLL,
  USER_TYPE_HOST,
} from '@/constants';
import * as api from '@/api';
import TargetAgeInput from '@/components/targetAgeInput';
import GenderInput from '@/components/genderInput';
import CategoryInput from '@/components/categoryInput';
import { getBidCapRecommendation } from '@/utils/bidCaps';
import { parseMessageFromError } from '@/utils/errors';
import WizardStepHeader from '@/components/wizardStepHeader';
import BidRecommendation from '@/components/bidRecommendation';
import BidWarning from '@/components/bidWarning';
import ConfirmDialog from '@/components/confirmDialog';
import HostingPlatformInput from './components/hostingPlatformInput';

const generateEmptyImportForm = () => ({
  importUrl: '',
});
const generateEmptyStepOneForm = () => ({
  name: '',
  hostName: '',
  hostEmail: '',
  averageMonthlyDownloads: null,
  tapProgramId: null,
  showDescription: '',
});
const generateEmptyStepTwoForm = () => ({
  targetGender: GENDER_ALL,
  targetAgeRanges: [...AGE_GROUP_OPTIONS],
  categories: [],
  categoriesBlacklist: [],
  approvalBehavior: AD_APPROVAL_BEHAVIOR_AUTOMATIC,
  adPreferences: [
    {
      adRunSlot: AD_RUN_SLOT_PRE_ROLL,
      adLength: AD_LENGTH_30_SECONDS,
      minimumCpm: getBidCapRecommendation(
        USER_TYPE_HOST,
        AD_RUN_SLOT_PRE_ROLL,
        AD_LENGTH_30_SECONDS,
      ).min,
    },
  ],
});

export default {
  components: {
    WizardStepHeader,
    TargetAgeInput,
    GenderInput,
    CategoryInput,
    BidRecommendation,
    BidWarning,
    ConfirmDialog,
    HostingPlatformInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    program: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
    sensitiveCategoryOptions: {
      type: Array,
      default: () => ([]),
    },
    initialPreviousButtonText: {
      type: String,
      default: 'Cancel',
    },
  },
  computed: {
    previousButtonText() {
      switch (this.activeStep) {
        case 1:
          return this.initialPreviousButtonText;
        case 2:
          return 'Previous';
        default:
          return 'Previous';
      }
    },
    nextButtonText() {
      switch (this.activeStep) {
        case 1:
          return 'Next';
        case 2:
          return 'Submit';
        default:
          return 'Next';
      }
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        this.activeStep = 1;

        if (newVal === true && this.program) {
          this.stepOneForm = {
            name: this.program.name || '',
            hostName: this.program.contact_name || '',
            hostEmail: this.program.contact_email || '',
            averageMonthlyDownloads: this.program.avg_listeners || null,
            tapProgramId: !Number.isNaN(Number(this.program.tap_program_id))
              && this.program.tap_program_id !== null
              ? Number(this.program.tap_program_id)
              : null,
            showDescription: this.program.description || '',
          };

          const targetAgeRanges = Object.keys(this.program.target_age_ranges);
          const adPreferences = this.program.ad_preferences.map((item) => ({
            adRunSlot: item.adRunSlot,
            adLength: item.length,
            minimumCpm: item.min_cpm,
          }));

          const categories = this.program.program_categories
            ? this.program.program_categories.map((category) => Number(category.id))
            : [];

          const categoriesBlacklist = this.program.blacklisted_categories
            ? this.program.blacklisted_categories.map((category) => Number(category.id))
            : [];

          this.stepTwoForm = {
            targetGender: this.program.target_gender || GENDER_ALL,
            targetAgeRanges,
            categories,
            categoriesBlacklist,
            adPreferences,
            approvalBehavior: this.program.approval_behavior || AD_APPROVAL_BEHAVIOR_AUTOMATIC,
          };
        } else if (newVal === true && !this.program) {
          this.importForm = generateEmptyImportForm();
          this.stepOneForm = generateEmptyStepOneForm();
          this.stepTwoForm = generateEmptyStepTwoForm();
        }

        // set up form changes watchers
        if (newVal === true) {
          this.formHasChanges = false;

          if (this.stepOneUnwatch) this.stepOneUnwatch();
          this.stepOneUnwatch = this.$watch('stepOneForm', function stepOneWatch() {
            this.formHasChanges = true;
          }, {
            deep: true,
          });

          if (this.stepTwoUnwatch) this.stepTwoUnwatch();
          this.stepTwoUnwatch = this.$watch('stepTwoForm', function stepTwoWatch() {
            this.formHasChanges = true;
          }, {
            deep: true,
          });
        }

        if (newVal === true) {
          window.addEventListener('beforeunload', this.beforeUnload);
        } else {
          window.removeEventListener('beforeunload', this.beforeUnload);
        }
      },
    },
    activeStep() {
      if (this.$refs.dialogBody) {
        this.$refs.dialogBody.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
  },
  data() {
    return {
      // unsaved changes feature
      formHasChanges: false,
      stepOneUnwatch: null,
      stepTwoUnwatch: null,
      unsavedChangesDialogIsVisible: false,

      AD_APPROVAL_BEHAVIOR_MANUAL,
      AD_APPROVAL_BEHAVIOR_AUTOMATIC,
      AD_RUN_SLOT_OPTIONS,
      AD_LENGTH_OPTIONS,
      USER_TYPE_HOST,
      activeStep: 1,
      importSchema: object({
        importUrl: string().required('Url is required'),
      }),
      importForm: generateEmptyImportForm(),
      isImportSubmitting: false,
      stepOneSchema: object({
        name: string().required('Name is required'),
        hostName: string().required('Host name is required'),
        hostEmail: string().email().required('Host email is required'),
        showDescription: string().required('Show description is required'),
      }),
      stepOneForm: generateEmptyStepOneForm(),
      stepTwoSchema: object({
        categories: array().min(1, 'Must choose at least 1 category').required(),
      }),
      stepTwoForm: generateEmptyStepTwoForm(),
    };
  },
  methods: {
    onToggle(visible) {
      if (this.formHasChanges) {
        this.unsavedChangesDialogIsVisible = true;
        return;
      }

      this.$emit('update:visible', visible);
    },
    beforeUnload(event) {
      if (this.formHasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-alert
        alert('There are unsaved changes to your podcast. Are you sure you want to leave?');
      }
    },
    async onSubmitImport(values) {
      try {
        this.isImportSubmitting = true;

        const res = await api.scrapeProgramMetadata({
          programUrl: values.importUrl,
        });

        if (
          res.metadata
          && res.metadata.channel
          && res.metadata.channel.title
          && typeof res.metadata.channel.title === 'string'
        ) {
          this.stepOneForm.name = res.metadata.channel.title;
        } else {
          this.stepOneForm.name = '';
        }

        if (
          res.metadata
          && res.metadata.channel
          && res.metadata.channel.description
          && typeof res.metadata.channel.description === 'string'
        ) {
          this.stepOneForm.showDescription = res.metadata.channel.description;
        } else {
          this.stepOneForm.showDescription = '';
        }

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully imported podcast data',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error importing podcast data');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isImportSubmitting = false;
      }
    },
    onSubmitStepOne() {
      this.activeStep = 2;
    },
    onSubmitStepTwo() {
      this.$emit('submit', {
        program: this.program,
        programForm: {
          ...this.stepOneForm,
          ...this.stepTwoForm,
        },
      });
    },
    onAddPreference() {
      this.stepTwoForm.adPreferences.push({
        adRunSlot: AD_RUN_SLOT_PRE_ROLL,
        adLength: AD_LENGTH_30_SECONDS,
        minimumCpm: getBidCapRecommendation(
          USER_TYPE_HOST,
          AD_RUN_SLOT_PRE_ROLL,
          AD_LENGTH_30_SECONDS,
        ).min,
      });
    },
    onDeletePreference(preferenceIndex) {
      this.stepTwoForm.adPreferences = this.stepTwoForm.adPreferences
        .filter((item, index) => index !== preferenceIndex);
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onClickPrevious() {
      switch (this.activeStep) {
        case 1:
          this.onToggle(false);
          break;
        case 2:
          this.activeStep = 1;
          break;
        default:
          // do nothing
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
