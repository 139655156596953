<template>
 <Dialog
    :visible="visible"
    :dismissableMask="!isSubmitting"
    modal
    :header="header"
    @update:visible="onClose"
    :pt="{
      closeButton: {
        disabled: isSubmitting,
      },
      content: {
        style: {
          maxWidth: '400px',
        },
      },
    }"
  >
    <VeeForm
      v-slot="{ handleSubmit }"
      :validation-schema="schema"
      as="div"
      @invalidSubmit="onInvalidSubmit"
    >
      <form
        id="user-form"
        @submit.prevent="handleSubmit($event, onSubmit)"
      >
        <!-- first & last name -->
        <div
          v-if="!disableNameFields"
          class="grid"
        >
          <div class="col-6">
            <BaseInput
              v-model="userForm.firstName"
              fieldId="firstName"
              fieldName="firstName"
              fieldLabel="First Name"
            />
          </div>
          <div class="col-6">
            <BaseInput
              v-model="userForm.lastName"
              fieldId="lastName"
              fieldName="lastName"
              fieldLabel="Last Name"
            />
          </div>
        </div>
        <!-- email -->
        <BaseInput
          v-model="userForm.email"
          fieldId="email"
          fieldName="email"
          fieldLabel="Email"
          type="email"
        />
        <!-- role -->
        <BaseDropdown
          v-model="userForm.roleId"
          fieldId="roleId"
          fieldName="roleId"
          fieldLabel="Role"
          placeholder="Choose a role"
          :options="roleOptions"
        />
      </form>
    </VeeForm>

    <template #footer>
      <div class="flex justify-content-end">
      <Button
        text
        plain
        label="Cancel"
        :disabled="isSubmitting"
        @click="$emit('update:visible', false)"
      />
      <Button
        class="ml-2"
        label="Submit"
        :loading="isSubmitting"
        type="submit"
        form="user-form"
      />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { object, string, number } from 'yup';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'Edit User',
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    user: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    roleOptions: {
      type: Array,
    },
    disableNameFields: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        if (newVal === true && this.user) {
          this.userForm = {
            firstName: this.user.fname || '',
            lastName: this.user.lname || '',
            email: this.user.email || '',
            roleId: !Number.isNaN(this.user.role_id)
              ? Number(this.user.role_id)
              : null,
          };
        }
      },
    },
    disableNameFields: {
      immediate: true,
      handler(newVal) {
        this.schema = object({
          firstName: string().when([], {
            is: () => newVal === true,
            then: (schema) => schema,
            otherwise: (schema) => schema.required('First name is required'),
          }),
          email: string().email().required('Email is required'),
          roleId: number().required('Role is required'),
        });
      },
    },
  },
  data() {
    return {
      schema: object(),
      userForm: {
        firstName: '',
        lastName: '',
        email: '',
        roleId: null,
      },
    };
  },
  methods: {
    onClose() {
      if (this.isCloseDisabled) return;

      this.$emit('update:visible', false);
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    async onSubmit() {
      this.$emit('submit', {
        user: this.user,
        userForm: this.userForm,
      });
    },
  },
};
</script>
