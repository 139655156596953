<template>
  <Message
    v-if="showWarning"
    class="m-0"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal text-sm',
      },
    }"
  >
    {{ warningMessage }}
  </Message>
</template>

<script>
import { USER_TYPE_ADVERTISER, USER_TYPE_HOST } from '@/constants';
import { getBidCapRecommendation } from '@/utils/bidCaps';

export default {
  props: {
    userType: String,
    adRunSlot: String,
    adLength: Number,
    cpm: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  computed: {
    showWarning() {
      if (this.cpm === null
        || this.bidCapRecommendation.min === null
        || this.bidCapRecommendation.max === null
      ) {
        return false;
      }

      switch (this.userType) {
        case USER_TYPE_ADVERTISER:
          return this.cpm < this.bidCapRecommendation.min;
        case USER_TYPE_HOST:
          return this.cpm > this.bidCapRecommendation.max;
        default:
          return false;
      }
    },
    warningMessage() {
      switch (this.userType) {
        case USER_TYPE_ADVERTISER:
          return `Your bid cap CPM is below our recommendation.
            This may limit the amount of inventory we can provide to your advertisement.`;
        case USER_TYPE_HOST:
          return `Your minimum CPM exceeds our recommendation.
            This may limit the amount of advertisers we can match
            to your podcast.`;
        default:
          return 'Your CPM is not within the range of our recommendation.';
      }
    },
    bidCapRecommendation() {
      return getBidCapRecommendation(
        this.userType,
        this.adRunSlot,
        this.adLength,
      );
    },
  },
};
</script>
