import ekozAxios from './instance/axios';

export const createAdvertiser = ({
  organizationId,
  name,
}) => ekozAxios.request({
  method: 'post',
  url: '/ssp/new_advertiser.php',
  data: {
    organization_id: organizationId,
    name,
    iab_codes: ['IAB2'],
  },
});
