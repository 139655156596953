import { defineStore } from 'pinia';
import * as api from '@/api';
import { ANALYTICS_EVENT_TYPE_START, ANALYTICS_GROUP_BY_DAY } from '@/constants';

export const useAnalyticsStore = defineStore('analytics', {
  state: () => ({
    adImpressions: {},
    adImpressionsAreLoading: {},
    campaignImpressions: {},
    campaignImpressionsAreLoading: {},
  }),
  getters: {},
  actions: {
    async getAdImpressions({
      startDate,
      endDate,
      adId,
    }) {
      try {
        this.adImpressionsAreLoading[adId] = true;

        const res = await api.readImpressions({
          adId,
          startDate,
          endDate,
          eventType: ANALYTICS_EVENT_TYPE_START,
          groupBy: ANALYTICS_GROUP_BY_DAY,
        });

        this.adImpressions[adId] = res;
      } finally {
        this.adImpressionsAreLoading[adId] = false;
      }
    },
    async getCampaignImpressions({
      startDate,
      endDate,
      campaignId,
    }) {
      try {
        this.campaignImpressionsAreLoading[campaignId] = true;

        const res = await api.readImpressions({
          campaignId,
          startDate,
          endDate,
          eventType: ANALYTICS_EVENT_TYPE_START,
          groupBy: ANALYTICS_GROUP_BY_DAY,
        });

        this.campaignImpressions[campaignId] = res;
      } finally {
        this.campaignImpressionsAreLoading[campaignId] = false;
      }
    },
  },
});
