<template>
  <Dialog
    :visible="visible"
    :dismissableMask="!isSubmitting"
    modal
    :header="header"
    @update:visible="onClose"
    :pt="{
      closeButton: {
        disabled: isSubmitting,
      },
      content: {
        style: {
          maxWidth: '400px',
        },
      },
    }"
  >
    <div>
      <slot>
        <p>
          Are you sure you want to perform this action?
        </p>
      </slot>
    </div>

    <VeeForm
      class="mt-3"
      v-slot="{ handleSubmit }"
      as="div"
      :validationSchema="schema"
    >
      <form
        id="prompt-form"
        @submit.prevent="handleSubmit($event, onSubmit)"
      >
        <BaseTextarea
          v-model="form.input"
          fieldId="prompt-input"
          fieldName="input"
        />
      </form>
    </VeeForm>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          :label="cancelButtonLabel"
          :disabled="isSubmitting"
          @click="$emit('update:visible', false)"
        />
        <Button
          class="ml-2"
          :label="confirmButtonLabel"
          :loading="isSubmitting"
          type="submit"
          form="prompt-form"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { string } from 'yup';

const generateEmptyForm = () => ({
  input: '',
});

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'Confirm',
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    confirmButtonLabel: {
      type: String,
      default: 'Confirm',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
  },
  data() {
    return {
      form: generateEmptyForm(),
      schema: {
        input: string().required('Input is required'),
      },
    };
  },
  methods: {
    onClose(event) {
      if (this.isSubmitting) return;

      this.$emit('update:visible', event);
    },
    onSubmit() {
      this.$emit('confirm', this.form.input);
    },
  },
};
</script>
