<template>
  <OverlayPanel
    :id="id"
    class="p-3"
    ref="overlay"
    :style="{
      width: '400px',
    }"
    :breakpoints="{
      '400px': '100vw',
    }"
  >
    <h2 class="text-lg mt-0">
      Notifications
    </h2>
    <div
      v-for="(notification, index) in notifications"
      :key="notification.title"
    >
      <notification-message
        :title="notification.title"
        :content="notification.content"
        :link="notification.link"
        :severity="notification.severity"
        @dismiss-message="dismissMessage(index)"
      />
    </div>
    <p
      class="text-center border-bottom-1 border-top-1 border-gray-200 py-3"
      v-if="notifications.length === 0"
    >
      No new available
    </p>
    <Button
      class="w-full justify-content-center"
      link
      @click="onClickSeeAll"
    >
      See all notifications
    </Button>
  </OverlayPanel>
</template>

<script>
import { ROUTE_HOST_NOTIFICATIONS } from '@/router/routes';
import NotificationMessage from './notificationMessage';

export default {
  props: {
    id: {
      type: String,
      default: 'notifaction-overlay',
    },
  },
  components: {
    NotificationMessage,
  },
  data() {
    return {
      notifications: [
        {
          title: 'Advertise name request',
          content: 'Build, prototype and customize any design. Neat and simple, just in minutes',
          link: 'https://www.ekoz.ai/',
          dismissed: false,
        },
        {
          title: 'You need to check your preferences',
          content: 'Duis aute irure dolor in reprehenderit.',
          dismissed: false,
        },
        {
          title: 'You need to upgrade your account',
          content: 'Sunt in culpa qui officia deserunt mollit anim id est laborum.',
          link: 'https://www.ekoz.ai/',
          dismissed: false,
          severity: 'warn',
        },
      ],
    };
  },
  methods: {
    onClickSeeAll() {
      this.$router.push({
        name: ROUTE_HOST_NOTIFICATIONS,
      });
      this.toggle();
    },
    toggle(event) {
      if (this.$refs.overlay) {
        this.$refs.overlay.toggle(event);
      }
    },
    dismissMessage(removeIndex) {
      this.notifications = this.notifications.filter((item, index) => index !== removeIndex);
    },
  },
};
</script>
