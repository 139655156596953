import ekozAxios from './instance/axios';

export const createCampaign = ({
  campaignName,
  organizationId,
  startDate,
  endDate,
  maxBudget,
  budgetAllocation,
  targetGender,
  targetAges,
  allowExplicit,
  targetCategories,
  adRunSlot,
  adTitle,
  adLength,
  adScript,
  bidCap,
  frequencyCap,
  adDocument,
}) => {
  const formData = new FormData();

  const ONE_THOUSAND = 1000;
  const impressionGoal = bidCap === 0
    ? 0
    : Math.round((maxBudget / bidCap) * ONE_THOUSAND);

  formData.append('campaign_name', campaignName);
  formData.append('org_id', organizationId);
  formData.append('start_date', startDate);
  formData.append('end_date', endDate);
  formData.append('max_budget', maxBudget);
  formData.append('imp_goal', impressionGoal);
  formData.append('budget_allocation', budgetAllocation);
  formData.append('target_gender', targetGender);
  formData.append('allow_explicit', allowExplicit);
  formData.append('target_ages', JSON.stringify(targetAges));
  formData.append('target_categories', JSON.stringify(targetCategories));
  formData.append('ad_type', 'standard');
  formData.append('ad_run_slot', adRunSlot);
  formData.append('ad_title', adTitle);
  formData.append('ad_length', adLength);
  formData.append('ad_script', adScript || '');
  formData.append('bid_cap', bidCap);
  formData.append('frequency_cap', frequencyCap);

  if (adDocument) {
    formData.append('document', adDocument);
  }

  return ekozAxios.request({
    method: 'post',
    url: '/campaigns/new_campaign.php',
    data: formData,
  });
};

export const readCampaigns = ({
  organizationId,
}) => ekozAxios.request({
  method: 'get',
  url: '/campaigns/get_campaigns.php',
  params: {
    org_id: organizationId,
  },
});

export const updateCampaign = ({
  campaignId,
  campaignName,
  organizationId,
  startDate,
  endDate,
  maxBudget,
  budgetAllocation,
  targetGender,
  targetAges,
  allowExplicit,
  targetCategories,
  adRunSlot,
  status,
  adTitle,
  adLength,
  adScript,
  uploadedTalkingPointsDocumentS3Url,
  bidCap,
  frequencyCap,
  adDocument,
}) => {
  const formData = new FormData();

  const ONE_THOUSAND = 1000;
  const impressionGoal = bidCap === 0
    ? 0
    : Math.round((maxBudget / bidCap) * ONE_THOUSAND);

  formData.append('id', campaignId);
  formData.append('campaign_name', campaignName);
  formData.append('org_id', organizationId);
  formData.append('start_date', startDate);
  formData.append('end_date', endDate);
  formData.append('max_budget', maxBudget);
  formData.append('imp_goal', impressionGoal);
  formData.append('budget_allocation', budgetAllocation);
  formData.append('target_gender', targetGender);
  formData.append('allow_explicit', allowExplicit);
  formData.append('target_ages', JSON.stringify(targetAges));
  formData.append('target_categories', JSON.stringify(targetCategories));
  formData.append('ad_type', 'standard');
  formData.append('ad_run_slot', adRunSlot);
  formData.append('status', status);
  formData.append('ad_title', adTitle);
  formData.append('ad_length', adLength);
  formData.append('ad_script', adScript || '');
  formData.append('uploaded_talking_points_document_s3_url', uploadedTalkingPointsDocumentS3Url || '');
  formData.append('bid_cap', bidCap);
  formData.append('frequency_cap', frequencyCap);

  if (adDocument) {
    formData.append('document', adDocument);
  }

  return ekozAxios.request({
    method: 'post',
    url: '/campaigns/edit_campaign.php',
    data: formData,
  });
};

export const pauseCampaign = ({
  campaignId,
  action,
}) => {
  const formData = new FormData();

  formData.append('campaign_id', campaignId);
  formData.append('action', action);

  return ekozAxios.request({
    method: 'post',
    url: '/campaigns/pause_campaign.php',
    data: formData,
  });
};
