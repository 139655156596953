<template>
  <div>
    <div>
      <RadioButton
        v-model="selectedGroup"
        inputId="allAgeGroups"
        :value="SELECTED_GROUP_ALL"
      />
      <label
        class="ml-1"
        for="allAgeGroups"
      >
        All (13-85)
      </label>
    </div>
    <div class="mt-2">
      <RadioButton
        v-model="selectedGroup"
        inputId="selectAgeGroups"
        :value="SELECTED_GROUP_SOME"
      />
      <label
        class="ml-1"
        for="selectAgeGroups"
      >
        Age Groups
      </label>
    </div>
    <div class="mt-3 px-2">
      <div class="flex flex-wrap">
        <div
          class="flex align-items-center mr-3 mb-2"
          v-for="option in AGE_GROUP_OPTIONS"
          :key="option"
        >
          <Checkbox
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', [...$event])"
            :inputId="`option_${option}`"
            :value="option"
            :disabled="selectAgeGroupCheckboxesDisabled"
          />
          <label
            class="ml-1"
            :for="`option_${option}`"
          >
            {{ option }}
        </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AGE_GROUP_OPTIONS } from '@/constants';
import _isEqual from 'lodash/isEqual';

const SELECTED_GROUP_ALL = 'all';
const SELECTED_GROUP_SOME = 'some';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    selectAgeGroupCheckboxesDisabled() {
      return this.selectedGroup === 'all';
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.selectedGroup = _isEqual(newVal, AGE_GROUP_OPTIONS)
          ? SELECTED_GROUP_ALL
          : SELECTED_GROUP_SOME;
      },
    },
    selectedGroup(newVal) {
      if (newVal === SELECTED_GROUP_ALL) {
        this.$emit('update:modelValue', [...AGE_GROUP_OPTIONS]);
      }
    },
  },
  data() {
    return {
      selectedGroup: SELECTED_GROUP_ALL,
      SELECTED_GROUP_ALL,
      SELECTED_GROUP_SOME,
      AGE_GROUP_OPTIONS,
    };
  },
};
</script>
