// TODO - platforms are deprecated and replaced by hosting platforms. Remove this file when possible
import _sortBy from 'lodash/sortBy';

export const PROGRAM_PLATFORM_APPLE = 'Apple';
export const PROGRAM_PLATFORM_GOOGLE = 'Google';
export const PROGRAM_PLATFORM_SPOTIFY = 'Spotify';

export const PROGRAM_PLATFORM_ANCHOR = 'Anchor';
export const PROGRAM_PLATFORM_PODBEAN = 'Podbean';
export const PROGRAM_PLATFORM_STITCHER = 'Sticher';
export const PROGRAM_PLATFORM_OVERCAST = 'Overcast';
export const PROGRAM_PLATFORM_POCKET_CASTS = 'Pocket Casts';
export const PROGRAM_PLATFORM_CASTBOX = 'Castbox';
export const PROGRAM_PLATFORM_BREAKER = 'Breaker';
export const PROGRAM_PLATFORM_RADIO_PUBLIC = 'RadioPublic';
export const PROGRAM_PLATFORM_TUNE_IN = 'TuneIn';
export const PROGRAM_PLATFORM_I_HEART_RADIO = 'iHeartRadio';
export const PROGRAM_PLATFORM_PANDORA = 'Pandora';
export const PROGRAM_PLATFORM_RSS_FEED = 'RSS Feed';

export const VALID_PROGRAM_PLATFORMS = [
  PROGRAM_PLATFORM_APPLE,
  PROGRAM_PLATFORM_GOOGLE,
  PROGRAM_PLATFORM_SPOTIFY,
  PROGRAM_PLATFORM_ANCHOR,
  PROGRAM_PLATFORM_PODBEAN,
  PROGRAM_PLATFORM_STITCHER,
  PROGRAM_PLATFORM_OVERCAST,
  PROGRAM_PLATFORM_POCKET_CASTS,
  PROGRAM_PLATFORM_CASTBOX,
  PROGRAM_PLATFORM_BREAKER,
  PROGRAM_PLATFORM_RADIO_PUBLIC,
  PROGRAM_PLATFORM_TUNE_IN,
  PROGRAM_PLATFORM_I_HEART_RADIO,
  PROGRAM_PLATFORM_PANDORA,
  PROGRAM_PLATFORM_RSS_FEED,
];

export const PROGRAM_PLATFORM_OPTIONS = _sortBy(
  VALID_PROGRAM_PLATFORMS.map((item) => ({
    label: item,
    value: item,
  })),
  (item) => (typeof item.value === 'string' ? item.value.toLowerCase() : item.value),
);
