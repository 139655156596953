<template>
  <FileUpload
    :pt="{
      buttonbar: {
        class: 'p-0 border-0',
      },
      content: {
        class: {
          'border-dashed': true,
          'border-red-400': this.invalid
        },
        style: {
          borderRadius: '6px',
          borderTop: '1px solid #e2e8f0',
        },
      },
    }"
    @select="onFileSelect"
    ref="fileUpload"
    accept="
      application/vnd.openxmlformats-officedocument.wordprocessingml.document
      ,application/pdf"
  >
    <template #header><div><!-- empty --></div></template>
    <template #content="{}">
      <div class="text-center">
        <p class="mb-0">
          <i
            :style="{
              fontSize: '1.5em',
            }"
            class="pi pi-upload text-primary"
          />
        </p>
        <p class="mt-2">
          Drag your pdf or docx file here
        </p>
      <Button
        link
        label="Browse"
        @click="() => {
          this.$refs.fileUpload?.$refs?.fileInput?.click();
        }"
      />
      </div>
    </template>
  </FileUpload>
  <small
    v-if="error"
    class="text-red-400"
  >
    {{ error }}
  </small>
  <div
    v-if="modelValue"
    class="mt-2 selected-file px-3 py-2 flex justify-content-between">
    <div class="flex align-items-center ellipsis">
      <i class="pi pi-file text-gray-500 text-xl" />
      <span class="ml-2">{{ modelValue.name }}</span>
    </div>

    <div class="flex align-items-center pl-2">
      <Divider layout="vertical" />
      <span
        v-if="modelValue.size"
        class="text-sm text-right"
      >
        {{ $filters.formatBytes(modelValue.size, 0) }}
      </span>
      <Button
        class="ml-2"
        icon="pi pi-times"
        text
        plain
        @click="onRemoveFile()"
      />
    </div>
  </div>
</template>

<script>
import { ExistingDocument } from '@/utils/document';

export default {
  props: {
    modelValue: {
      validator: (prop) => prop === null
        || prop instanceof File
        || prop instanceof ExistingDocument,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  methods: {
    onFileSelect({ files }) {
      if (files.length > 0) {
        const selectedFile = files[files.length - 1];
        this.$emit('update:modelValue', selectedFile);
        // remove file from input. This is so user can select
        // file A, file B, then file A again if they want
        this.$refs.fileUpload.remove(0);
      }
    },
    onRemoveFile() {
      this.$emit('update:modelValue', null);
      this.$refs.fileUpload.remove(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-file {
  border: 1px #e2e8f0 solid;
  border-radius: 6px;
}
</style>
