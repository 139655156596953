import ekozAxios from './instance/axios';

export const login = ({
  email,
  password,
}) => ekozAxios.request({
  method: 'post',
  url: '/auth/login.php',
  data: {
    email,
    password,
  },
});

export const signUp = ({
  email,
  password,
  firstName,
  lastName,
  roleId,
}) => ekozAxios.request({
  method: 'post',
  url: '/auth/signup.php',
  data: {
    email,
    password,
    fname: firstName,
    lname: lastName,
    role_id: roleId,
  },
});

export const getMe = () => ekozAxios.request({
  method: 'get',
  url: 'auth/me.php',
});
