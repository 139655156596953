export class ExistingDocument {
  /**
   * ExistingDocument constructor
   *
   * @param {string} name
   * @param {string} url
   */
  constructor(name, url) {
    this.name = name;
    this.url = url;
  }
}
