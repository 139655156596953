<template>
  <PageContainer>
    <PageTitle>Profile</PageTitle>

    <UserSummaryCard
      :avatarLabel="myUserStore.userInitials"
      :username="myUserStore.fullname"
      :email="myUserStore.email"
      @logout="onLogout"
    />

    <!-- Profile Info -->
    <Card class="mt-4">
      <template #content>
        <VeeForm
          v-slot="{ handleSubmit }"
          :validation-schema="schema"
          as="div"
          @invalidSubmit="onInvalidSubmit"
        >
          <form
            @submit.prevent="handleSubmit($event, onSubmitProfileInfo)"
          >
            <h2 class="mt-0 text-xl">
              Profile Info
            </h2>
            <div class="grid">
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="profileForm.firstName"
                  fieldId="firstName"
                  fieldName="firstName"
                  fieldLabel="First Name"
                />
              </div>
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="profileForm.lastName"
                  fieldId="lastName"
                  fieldName="lastName"
                  fieldLabel="Last Name"
                />
              </div>
              <div class="col-12">
                <BaseInput
                  v-model="profileForm.email"
                  fileId="email"
                  fieldName="email"
                  fieldLabel="Email"
                  type="email"
                />
              </div>
            </div>
            <div class="flex justify-content-end">
              <Button
                type="submit"
                :loading="profileFormIsSubmitting"
                label="Save"
              />
            </div>
          </form>
        </VeeForm>
      </template>
    </Card>
  </PageContainer>
</template>

<script>
import { mapStores } from 'pinia';
import { object, string } from 'yup';
import { ROUTE_LOGIN } from '@/router/routes';
import { useAuthStore, useMyUserStore } from '@/stores';
import * as api from '@/api';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import UserSummaryCard from '@/components/userSummaryCard';
import { parseMessageFromError } from '@/utils/errors';

export default {
  components: {
    UserSummaryCard,
  },
  computed: {
    ...mapStores(useMyUserStore, useAuthStore),
  },
  data() {
    return {
      schema: object({
        email: string().email().required('Email is required'),
      }),
      profileForm: {
        firstName: '',
        lastName: '',
        email: '',
      },
      profileFormIsSubmitting: false,
    };
  },
  async mounted() {
    this.profileForm = {
      firstName: this.myUserStore.myUser
        ? this.myUserStore.myUser.fname
        : '',
      lastName: this.myUserStore.myUser
        ? this.myUserStore.myUser.lname
        : '',
      email: this.myUserStore.email,
    };
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onLogout() {
      this.authStore.logout();
      this.$router.push({ name: ROUTE_LOGIN });
    },
    async onSubmitProfileInfo() {
      try {
        this.profileFormIsSubmitting = true;

        await api.updateUser({
          userId: this.myUserStore.userId,
          firstName: this.profileForm.firstName,
          lastName: this.profileForm.lastName,
          email: this.profileForm.email,
        });
        await this.myUserStore.getMyUser();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated user',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.profileFormIsSubmitting = false;
      }
    },
  },
};
</script>
