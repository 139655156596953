export const AGE_GROUP_OPTIONS = [
  '13-17',
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65-70',
  '70+',
];
