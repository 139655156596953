import ekozAxios from './instance/axios';

export const updateUser = ({
  userId,
  firstName,
  lastName,
  email,
  description,
  personalBio,
  organizationId,
  showNotes,
  roleId,
}) => ekozAxios.request({
  method: 'post',
  url: '/users/edit_user.php',
  data: {
    id: userId,
    fname: firstName,
    lname: lastName,
    email,
    description,
    personal_bio: personalBio,
    organization_id: organizationId,
    show_notes: showNotes,
    role_id: roleId,
  },
});

export const readUsers = ({
  organizationId,
} = {}) => ekozAxios.request({
  method: 'get',
  url: '/users/get_users.php',
  params: {
    organization_id: organizationId,
  },
});

export const deleteUser = (userId) => ekozAxios.request({
  method: 'post',
  url: '/users/delete_user.php',
  data: {
    id: userId,
  },
});

export const inviteUser = ({
  organizationId,
  email,
}) => ekozAxios.request({
  method: 'post',
  url: '/users/invite_user.php',
  data: {
    organization_id: organizationId,
    email,
  },
});
