<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{
        errors,
        value: fieldValue,
        handleChange,
      }"
    >
      <div>
        <Checkbox
          :modelValue="fieldValue"
          @update:modelValue="handleChange"
          :inputId="fieldId"
          :aria-describedby="helperText ? `${fieldId}-help` : ''"
          v-bind="$attrs"
          :invalid="errors.length > 0"
        />
        <label
          class="ml-2"
          :for="fieldId"
          :class="{ 'text-red-400': errors.length > 0}"
        >
          {{ fieldLabel }}
        </label>
      </div>
      <small
        v-if="helperText"
        class="mt-2"
        v-html="helperText"
      />
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      validator: (prop) => typeof prop === 'string' || typeof prop === 'number' || typeof prop === 'boolean',
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
