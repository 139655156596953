import { defineStore, getActivePinia } from 'pinia';
import * as api from '@/api';
import { LOCAL_STORAGE_VOICEPRINT_KEY, LOCAL_STORAGE_USER_TOKEN_KEY } from '@/constants';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_KEY) || '',
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  actions: {
    async login({
      email,
      password,
    }) {
      try {
        const res = await api.login({
          email,
          password,
        });
        localStorage.setItem(LOCAL_STORAGE_USER_TOKEN_KEY, res.token);
        this.token = res.token;

        return res;
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_KEY);
        throw error;
      }
    },
    logout() {
      this.token = '';
      localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_VOICEPRINT_KEY);

      // reset all stores
      // eslint-disable-next-line no-underscore-dangle
      getActivePinia()._s.forEach((store) => store.$reset());
    },
  },
});
