import { ROUTE_LOGIN } from '@/router/routes';
import axios from 'axios';

export const boostrapAxiosInterceptor = (axiosInstance, router, authStore) => {
  axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      if (
        error
        && error.response
        && error.response.status === 401
      ) {
        // user is unauthorized, redirect back to login page
        authStore.logout();

        router.push({
          name: ROUTE_LOGIN,
        });
      }

      return Promise.reject(error && error.response ? error.response : error);
    },
  );
};
