import { defineStore } from 'pinia';
import * as api from '@/api';

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    usersAreLoading: false,
  }),
  getters: {
    getUsersByOrganizationId: (state) => (
      (organizationId) => state.users.filter((item) => item.organization_id === organizationId)
    ),
  },
  actions: {
    async getUsers({
      organizationId,
    } = {}) {
      try {
        this.usersAreLoading = true;

        const res = await api.readUsers({
          organizationId,
        });
        this.users = res;
      } finally {
        this.usersAreLoading = false;
      }
    },
  },
});
