<template>
  <Dialog
    class="with-footer-gradient"
    :visible="visible"
    dismissableMask
    modal
    :pt="{
      header: {
        class: 'block',
      },
      footer: {
        class: 'block',
         style: {
          'padding-top': '1.5rem',
        }
      }
    }"
    header="Add your mp3 Samples"
    :closable="false"
    @update:visible="onClose"
  >

    <Card class="wizard-container">
      <template #content>
        <p class="text-sm">
          Each sample should be at least 30 seconds in length and should be as clear
          as possible with no background noise. If available, use existing ads that
          you have recorded.
        </p>
        <FileUpload
          :pt="{
            buttonbar: {
              class: 'p-0 border-0',
            },
            content: {
              class: 'border-dashed',
              style: {
                borderRadius: '6px',
                borderTop: '1px solid #e2e8f0',
              },
            },
          }"
          @select="onFileSelect"
          ref="fileUpload"
          accept="audio/mpeg"
          :multiple="true"
        >
          <template #header><div><!-- empty --></div></template>
          <template #content="{}">
            <div class="text-center">
              <p class="mb-0">
                <i
                  :style="{
                    fontSize: '1.5em',
                  }"
                  class="pi pi-upload text-primary"
                />
              </p>
              <p class="mt-2">
                Drag your mp3 files here or
              </p>
            <Button
              link
              label="Browse"
              @click="() => {
                this.$refs.fileUpload?.$refs?.fileInput?.click();
              }"
            />
            </div>
          </template>
        </FileUpload>

        <ul class="pl-0 list-none">
          <li
            class="mt-2 selected-file px-3 py-2 flex justify-content-between"
            v-for="(file, index) in selectedFiles"
            :key="index"
          >
            <div class="flex align-items-center ellipsis">
              <i class="pi pi-file text-gray-500 text-xl" />
              <span class="ml-2">{{ file.name }}</span>
            </div>

            <div class="flex align-items-center pl-2">
              <Divider layout="vertical" />
              <span class="text-sm text-right">{{ $filters.formatBytes(file.size, 0) }}</span>
              <Button
                class="ml-2"
                icon="pi pi-times"
                text
                plain
                @click="onRemoveFile(index)"
              />
            </div>
          </li>
        </ul>
      </template>
    </Card>

    <template #footer>
      <div class="wizard-container flex justify-content-end">
        <Button
          text
          plain
          label="Cancel"
          :disabled="isSubmitting"
          @click="onClose"
        />
        <Button
          class="ml-2"
          label="Submit"
          :disabled="this.selectedFiles.length === 0"
          :loading="isSubmitting"
          @click="$emit('submit', this.selectedFiles)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';

export default {
  props: {
    visible: Boolean,
    submit: Function,
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  computed: {
    ...mapStores(useMyUserStore),
  },
  watch: {
    visible(newVal) {
      if (newVal === true) {
        // clear files
        this.selectedFiles = [];
      }
    },
  },
  methods: {
    onClose() {
      if (this.isSubmitting) return;

      this.$emit('update:visible', false);
    },
    onFileSelect({ files }) {
      this.selectedFiles = files;
    },
    onRemoveFile(index) {
      this.selectedFiles = this.selectedFiles.filter((file, fileIndex) => fileIndex !== index);

      // remove file from fileUpload's file list
      this.$refs.fileUpload.remove(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-file {
  border: 1px #e2e8f0 solid;
  border-radius: 6px;
}
</style>
