<template>
  <h2 class="text-base mt-0">
    Step {{ stepNumber }}
  </h2>
  <h1 class="text-2xl">
    {{ title }}
  </h1>
</template>

<script>
export default {
  props: {
    stepNumber: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
  },
};
</script>
