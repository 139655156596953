import { useAuthStore, useMyUserStore, useRolesStore } from '@/stores';
import {
  ROUTE_ADVERTISER_DASHBOARD,
  ROUTE_ERROR,
  ROUTE_HOST_DASHBOARD,
  ROUTE_HOST_HOME,
  ROUTE_UNAUTHORIZED,
} from './routes';

export const publicGuard = (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

export const authHostGuard = async (to, from, next) => {
  try {
    const authStore = useAuthStore();
    const myUserStore = useMyUserStore();
    const rolesStore = useRolesStore();

    await myUserStore.getMyUser();

    if (!authStore.isAuthenticated) {
      next('/login');
      return;
    }

    await rolesStore.getRoles();

    if (myUserStore.isHostUser) {
      next();
    } else if (myUserStore.isAdvertiserUser) {
      next({ name: ROUTE_ADVERTISER_DASHBOARD });
    } else {
      next({ name: ROUTE_UNAUTHORIZED });
    }
  } catch (error) {
    next({ name: ROUTE_ERROR });
  }
};

export const authAdvertiserGuard = async (to, from, next) => {
  try {
    const authStore = useAuthStore();
    const myUserStore = useMyUserStore();
    const rolesStore = useRolesStore();

    await myUserStore.getMyUser();

    if (!authStore.isAuthenticated) {
      next('/login');
      return;
    }

    await rolesStore.getRoles();

    if (myUserStore.isAdvertiserUser) {
      next();
    } else if (myUserStore.isHostUser) {
      next({ name: ROUTE_HOST_DASHBOARD });
    } else {
      next({ name: ROUTE_UNAUTHORIZED });
    }
  } catch (error) {
    next({ name: ROUTE_ERROR });
  }
};

export const beforeEnterHostOrganizationGuard = async (to, from, next) => {
  const myUserStore = useMyUserStore();

  if (myUserStore.myOrganization) {
    next();
  } else {
    next({ name: ROUTE_HOST_HOME });
  }
};
