<template>
  <Card>
    <template #content>
      <h2 class="mt-0 text-xl">
        Generate Ad from Document
      </h2>
      <p>
        Upload the "talking points" document to generate an ad.
        This is typically provided by an advertiser and describes
        the product or service being advertised.
      </p>
      <div class="grid">
        <div class="col-12">
          <BaseDropdown
            v-model="form.adLength"
            fieldId="adLength"
            fieldName="adLength"
            fieldLabel="Ad Length"
            placeholder="Choose an ad length"
            :options="AD_LENGTH_OPTIONS"
          />
        </div>
      </div>
      <FileUpload
        :pt="{
          buttonbar: {
            class: 'p-0 border-0',
          },
          content: {
            class: 'border-dashed',
            style: {
              borderRadius: '6px',
              borderTop: '1px solid #e2e8f0',
            },
          },
        }"
        @select="onFileSelect"
        ref="fileUpload"
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document
          ,application/pdf"
      >
        <template #header><div><!-- empty --></div></template>
        <template #content="{}">
          <div class="text-center">
            <p class="mb-0">
              <i
                :style="{
                  fontSize: '1.5em',
                }"
                class="pi pi-upload text-primary"
              />
            </p>
            <p class="mt-2">
              Drag your pdf or docx file here
            </p>
          <Button
            link
            label="Browse"
            @click="() => {
              this.$refs.fileUpload?.$refs?.fileInput?.click();
            }"
          />
          </div>
        </template>
      </FileUpload>
      <div
        v-if="form.selectedFile"
        class="mt-2 selected-file px-3 py-2 flex justify-content-between">
        <div class="flex align-items-center ellipsis">
          <i class="pi pi-file text-gray-500 text-xl" />
          <span class="ml-2">{{ form.selectedFile.name }}</span>
        </div>

        <div class="flex align-items-center pl-2">
          <Divider layout="vertical" />
          <span class="text-sm text-right">
            {{ $filters.formatBytes(form.selectedFile.size, 0) }}
          </span>
          <Button
            class="ml-2"
            icon="pi pi-times"
            text
            plain
            @click="onRemoveFile()"
          />
        </div>
      </div>
      <div class="mt-3 flex justify-content-end">
        <Button
          label="Generate"
          :loading="adIsLoading"
          :disabled="!form.selectedFile"
          @click="onGenerateAd"
        />
      </div>

      <Card
        v-if="adUrl"
        class="bg-gray-100 mt-3"
        :pt="{
          body: {
            class: 'p-2'
          }
        }"
      >
        <template #content>
          <audio
            class="w-full"
            :src="adUrl"
            controls
          />
        </template>
      </Card>

      <template v-if="adUrl">
        <Divider />

        <BaseTextarea
          v-model="form.adScript"
          fieldId="adScript"
          fieldName="adScript"
          fieldLabel="Ad Script"
          helperText="This is the ad script generated from the uploaded document. Modify the script
            and click &quot;Generate Verbatim&quot; to make any changes."
        />
        <div class="mt-3 flex justify-content-end">
          <Button
            label="Generate Verbatim"
            :loading="adVerbatimIsLoading"
            :disabled="!form.adScript"
            @click="onGenerateAdVerbatim"
          />
        </div>
        <Card
          v-if="adVerbatimUrl"
          class="bg-gray-100 mt-3"
          :pt="{
            body: {
              class: 'p-2'
            }
          }"
        >
          <template #content>
            <audio
              class="w-full"
              :src="adVerbatimUrl"
              controls
            />
          </template>
        </Card>
      </template>
    </template>
  </Card>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import * as api from '@/api';
import { AD_LENGTH_OPTIONS } from '@/constants';
import { parseMessageFromError } from '@/utils/errors';

export default {
  computed: {
    ...mapStores(useMyUserStore),
  },
  data() {
    return {
      AD_LENGTH_OPTIONS,
      form: {
        adLength: 30,
        selectedFile: null,
        adScript: '',
      },
      adIsLoading: false,
      adVerbatimIsLoading: false,
      adUrl: null,
      adVerbatimUrl: null,
    };
  },
  methods: {
    resetState() {
      this.adUrl = null;
      this.adVerbatimUrl = null;
      this.form.adScript = '';
    },
    onFileSelect({ files }) {
      if (files.length > 0) {
        const selectedFile = files[files.length - 1];
        this.form.selectedFile = selectedFile;
        // remove file from input. This is so user can select
        // file A, file B, then file A again if they want
        this.$refs.fileUpload.remove(0);
      }
    },
    onRemoveFile() {
      this.form.selectedFile = null;
      this.$refs.fileUpload.remove(0);
    },
    async onGenerateAd() {
      try {
        this.adIsLoading = true;

        // reset state. This is needed when a user updates the ad script verbatim.
        // we want to clear the state so the user follows the correct order of steps
        this.resetState();

        const scriptMinutes = Math.floor(this.form.adLength / 60);
        const scriptSeconds = this.form.adLength % 60;

        const res = await api.createGeneratedAd({
          userId: this.myUserStore.userId,
          adFile: this.form.selectedFile,
          scriptMinutes,
          scriptSeconds,
        });

        this.adUrl = res.s3_url;
        this.form.adScript = res.script;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating ad.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.adIsLoading = false;
      }
    },
    async onGenerateAdVerbatim() {
      try {
        this.adVerbatimIsLoading = true;

        const res = await api.createTextToSpeech({
          userId: this.myUserStore.userId,
          text: this.form.adScript,
        });

        this.adVerbatimUrl = res.s3_url;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating ad verbatim.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.adVerbatimIsLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-file {
  border: 1px #e2e8f0 solid;
  border-radius: 6px;
}
</style>
