<template>
  <Card
    :class="{
      'analytics-card': true,
      'border-left-green': percentageChange > 0,
      'border-left-red': percentageChange < 0,
    }"
    :pt="{
      title: {
        class: 'text-lg',
      }
    }"
  >
    <template #title>{{ title }}</template>
    <template #content>
      <p class="mt-0 mb-2 text-2xl font-bold">
        {{ formattedValue }}
      </p>
      <!-- <div
        :class="{
          'text-sm': true,
          'text-green-400': percentageChange > 0,
          'text-red-400': percentageChange < 0,
        }"
      >
        <i class="pi pi-arrow-circle-up" />
        <span class="ml-1">
          {{ percentageChange > 0 ? `+${percentageChange}` : percentageChange }}% this week
        </span>
      </div> -->
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    title: String,
    value: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    percentageChange: Number,
    currency: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return typeof this.value === 'number'
        ? this.value.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          style: this.currency ? 'currency' : undefined,
          currency: this.currency ? 'USD' : undefined,
        })
        : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-card {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    border-radius: 12px 0 0 12px;
    background-color: var(--gray-400)
  }

  &.border-left-green {
    &:before {
      background-color: var(--green-400)
    }
  }
  &.border-left-red {
    &:before {
      background-color: var(--red-400)
    }
  }
}
</style>
