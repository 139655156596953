<template>
  <Card
    :pt="{
      body: {
        class: 'px-4 py-3 line-height-3',
      },
    }"
  >
    <template #content>
      <div class="grid grid-nogutter">
        <div class="col-3">
          <Skeleton width="50px" height="17px" />
          <Skeleton class="mt-2" width="140px" height="21px" />
        </div>

        <div class="col-2">
          <Skeleton width="120px" height="17px" />
          <Skeleton class="mt-2" width="105px" height="21px" />
        </div>

        <div class="col-2 flex align-items-center">
          <Skeleton width="70px" height="21px" />
        </div>

        <div class="col-2 flex align-items-center">
          <Skeleton width="105px" height="32px" />
        </div>

          <div class="col-3">
            <Skeleton height="3rem" />
          </div>
      </div>
    </template>
    </Card>
</template>
