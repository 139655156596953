 <template>
  <Card
    class="bg-gray-100"
    :pt="{
      body: {
        class: 'p-2'
      }
    }"
  >
    <template #content>
      <div class="flex">
        <div class="flex flex-grow-1 px-2">
          <audio
            class="w-full"
            controls
            :src="sample.s3_url"
          />
        </div>
        <div class="flex">
          <Divider layout="vertical" />
          <Button
            icon="pi pi-ellipsis-v"
            text
            plain
            aria-haspopup="true"
            :aria-controls="`row-voice-sample-${sample.id}`"
            @click="(event) => this.$refs.voiceSampleDropdown.toggle(event)"
          />
          <Menu
            :id="`row-voice-sample-${sample.id}`"
            class="right-align-menu"
            ref="voiceSampleDropdown"
            popup
            :model="dropdownItems"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    sample: Object,
  },
  data() {
    return {
      dropdownItems: [
        {
          label: 'Remove',
          command: () => {
            this.$emit('deleteSample', this.sample);
          },
        },
      ],
    };
  },
};
</script>
